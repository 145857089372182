import React from "react"
import {
  CloseHolder,
  StepTwoTitle,
  StepTwoWrapper,
  IconHolder,
  Icon,
  Item,
  ItemHolder,
  TopSection,
  Disclosure,
  BottomSection,
  BottomSectioTitle,
  HeartIcon,
  BottomSectionText,
  BottomFlex,
  IconFlex,
  LinkHolder,
  RightSection,
} from "./step2-mobile.styled"
import { EulaText, PreviewTitles } from "../../shared/shared.styles"
import CloseIcon from "../../images/closeIcon.svg"
import EulaDocx from "../../images/EulaDocx.svg"
import EulaTxt from "../../images/EulaTxt.svg"
import EaulaEmail from "../../images/EulaEmail.svg"
import LinkedIn from "../../images/linkedin.svg"
import Twitter from "../../images/twitter.svg"
import Facebook from "../../images/facebook.svg"
import Reddit from "../../images/reddit.svg"

const Step2Mobile = () => {
  return (
    <StepTwoWrapper>
      <TopSection>
        <PreviewTitles>It’s DONE!</PreviewTitles>
        <StepTwoTitle>Thank you for using our EULA Generator!</StepTwoTitle>
        <ItemHolder>
          <Item>
            <Icon src={EulaDocx} />
            <EulaText>Download as .docx</EulaText>
          </Item>
          <Item>
            <Icon src={EulaTxt} />
            <EulaText>Download as .txt</EulaText>
          </Item>
          <Item>
            <Icon src={EaulaEmail} />
            <EulaText>Send as Email</EulaText>
          </Item>
        </ItemHolder>
        <EulaText maxw={502}>
          *If you need to make any revisions to the file, you can easily do so
          by changing your answers on our EULA generator. Simply revisit the
          platform, make the necessary adjustments, and generate a new version
          of your EULA.
        </EulaText>
        <Disclosure>
          <strong>Disclosure:</strong>
          <br />
          Data entered into the generator is stored locally on your device as
          temporary data. Once your session ends, this temporary data is
          automatically deleted.
        </Disclosure>
      </TopSection>
      <BottomSection>
        <BottomSectioTitle>
          Sharing is caring! <HeartIcon>♥</HeartIcon>
        </BottomSectioTitle>
        <BottomFlex>
          <BottomSectionText>
            Share your experience with others and support us. We value your
            feedback and are here to assist you.
          </BottomSectionText>
          <RightSection>
            <IconFlex>
              <img src={LinkedIn} />
              <img src={Twitter} />
              <img src={Facebook} />
              <img src={Reddit} />
            </IconFlex>
            <LinkHolder>https://licencespring.com/eula-generator/</LinkHolder>
          </RightSection>
        </BottomFlex>
      </BottomSection>
    </StepTwoWrapper>
  )
}

export default Step2Mobile
