import React from "react"
import { OrderedList } from "./tab.styles"

const WarrantyLiability = ({ EulaData }) => {
  const { softwareForm, privacyForm } = EulaData
  const { softwareName } = softwareForm
  const { monetaryCap } = privacyForm

  return (
    <div>
      <OrderedList>
        <li>
          Limited warranty. THE{" "}
          {!softwareName ? "[Software_Name]" : softwareName} SOFTWARE IS
          PROVIDED “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS”, WITHOUT
          WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY THE
          APPLICABLE LAW, WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR
          IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
          AND ANY WARRANTY THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
          PERFORMANCE, USAGE OR TRADE PRACTICE.
        </li>
        <li>
          Disclaimer of liability. IN NO EVENT SHALL WE BE LIABLE FOR ANY
          DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR
          CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
          SUBSTITUTE GOODS OR SERVICES, COMPUTER FAILURE OR MALFUNCTION, LOSS OF
          DATA OR PROFITS, BUSINESS INTERRUPTION, ETC.) HOWEVER CAUSED AND ON
          ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR
          TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE
          USE OF THE {!softwareName ? "[Software_Name]" : softwareName}{" "}
          SOFTWARE, EVEN IF WE ARE AWARE OF OR HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGE.
        </li>
        {monetaryCap && (
          <li>
            Remedies. EXCEPT FOR CLAIMS WITH RESPECT TO FRAUD, DEATH OR PERSONAL
            INJURY, FOR WHICH NO LIMIT SHALL APPLY, IN NO EVENT – TO THE MAXIMUM
            EXTENT PERMITTED BY THE APPLICABLE LAW – OUR LIABILITY SHALL IN THE
            AGGREGATE EXCEED {monetaryCap}.
          </li>
        )}
        <li>
          Mandatory legal provisions. Some jurisdictions do not allow certain
          limitations of warranty or liability, therefore some or all of the
          limitations above provided may not apply to You. In such case, the
          limitations provided herein shall be applicable to the fullest and
          maximum extent permitted by the applicable Law.
        </li>
      </OrderedList>
    </div>
  )
}

export default WarrantyLiability
