import React, { useEffect, useState } from "react"
import {
  CheckBoxItem,
  PopupHolder,
  QuestionIcon,
  QuestionWrapper,
} from "./company-questions.styled"
import {
  InputErrorMessage,
  InputHolder,
  InputTitle,
  QuestionSpan,
} from "../../../shared/shared.styles"
import { CheckBox, CheckBoxHolder, EulaInput } from "../../../shared/input"
import QuestionMarkIcon from "../../../images/question-mark-outline-svgrepo-com.svg"

const CompanyQuestions = ({
  stepsCompleted,
  companyForm,
  setCompanyForm,
  companyFormValidity,
  setCompanyFormValidity,
}) => {
  const [popup, setPopup] = useState(null)

  const {
    isCompany,
    companyName,
    registeredAdress,
    businessRegistrationNumber,
    commercialScope,
    taxCode,
    policyLink,
    taxCodeToggled,
  } = companyForm

  const {
    companyNameValid,
    registeredAdressValid,
    businessRegistrationNumberValid,
    taxCodeValid,
    policyLinkValid,
  } = companyFormValidity

  useEffect(() => {
    setCompanyFormValidity({
      ...companyFormValidity,
      companyNameValid: true,
    })
  }, [companyName])

  useEffect(() => {
    setCompanyFormValidity({
      ...companyFormValidity,
      registeredAdressValid: true,
    })
  }, [registeredAdress])

  useEffect(() => {
    setCompanyFormValidity({
      ...companyFormValidity,
      businessRegistrationNumberValid: true,
    })
  }, [businessRegistrationNumber])

  useEffect(() => {
    setCompanyFormValidity({
      ...companyFormValidity,
      taxCodeValid: true,
    })
  }, [taxCode])

  useEffect(() => {
    setCompanyFormValidity({
      ...companyFormValidity,
      policyLinkValid: true,
    })
  }, [policyLink])

  return (
    <QuestionWrapper show={stepsCompleted === 1}>
      <InputHolder bottom checkbox>
        <InputTitle>Is Your Entity a Company?</InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setCompanyForm({
                  ...companyForm,
                  isCompany: true,
                })
              }}
              type="checkbox"
              checked={isCompany}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setCompanyForm({
                  ...companyForm,
                  isCompany: false,
                })
              }}
              type="checkbox"
              checked={!isCompany}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
      </InputHolder>
      <InputHolder bottom>
        <InputTitle>
          {isCompany
            ? "What Is the Name of Your Company?"
            : "What is Your Name?"}
        </InputTitle>
        <EulaInput
          value={companyName}
          onChange={(e) => {
            setCompanyForm({
              ...companyForm,
              companyName: e.target.value,
            })
          }}
          placeholder={isCompany ? "e.g. Photoshop" : "e.g. John Smith"}
        />
        {!companyNameValid && (
          <InputErrorMessage>
            {isCompany
              ? "Please enter your company name."
              : "Please enter your name"}
          </InputErrorMessage>
        )}
      </InputHolder>
      <InputHolder bottom>
        <InputTitle>
          {isCompany
            ? "What Is Your Full Registered Address?"
            : "What is Your Full Address?"}
        </InputTitle>
        <EulaInput
          value={registeredAdress}
          onChange={(e) => {
            setCompanyForm({
              ...companyForm,
              registeredAdress: e.target.value,
            })
          }}
          placeholder="e.g. 123 Main Street, Cityville, Stateland, 12345"
        />
        {!registeredAdressValid && (
          <InputErrorMessage>Please enter a valid address.</InputErrorMessage>
        )}
      </InputHolder>
      {isCompany && (
        <InputHolder bottom>
          <InputTitle>What Is Your Business Registration Number?</InputTitle>
          <EulaInput
            onChange={(e) => {
              setCompanyForm({
                ...companyForm,
                businessRegistrationNumber: e.target.value,
              })
            }}
            value={businessRegistrationNumber}
            placeholder="e.g. 1234567890"
          />
          {!businessRegistrationNumberValid && (
            <InputErrorMessage>
              Please enter a valid business registration number.
            </InputErrorMessage>
          )}
        </InputHolder>
      )}
      <InputHolder bottom checkbox>
        <InputTitle>
          Does the End-User License Agreement Have a Commercial Scope?
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setCompanyForm({
                  ...companyForm,
                  commercialScope: true,
                })
              }}
              type="checkbox"
              checked={commercialScope}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setCompanyForm({
                  ...companyForm,
                  commercialScope: false,
                })
              }}
              type="checkbox"
              checked={!commercialScope}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
      </InputHolder>
      <InputHolder bottom checkbox>
        <InputTitle>Include Tax Code?</InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setCompanyForm({
                  ...companyForm,
                  taxCodeToggled: true,
                })
              }}
              type="checkbox"
              checked={taxCodeToggled}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setCompanyForm({
                  ...companyForm,
                  taxCodeToggled: false,
                })
              }}
              type="checkbox"
              checked={!taxCodeToggled}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
      </InputHolder>
      {taxCodeToggled && (
        <InputHolder bottom>
          <InputTitle>
            What Is Your Tax Code?
            <QuestionSpan
              onMouseEnter={() => setPopup(0)}
              onMouseLeave={() => setPopup(null)}
            >
              <QuestionIcon src={QuestionMarkIcon} />
            </QuestionSpan>
          </InputTitle>
          <EulaInput
            value={taxCode}
            onChange={(e) => {
              setCompanyForm({
                ...companyForm,
                taxCode: e.target.value,
              })
            }}
            placeholder="e.g. 1234567890"
          />
          {!taxCodeValid && (
            <InputErrorMessage>
              Please enter a valid tax code.
            </InputErrorMessage>
          )}

          <PopupHolder
            onMouseEnter={() => setPopup(0)}
            onMouseLeave={() => setPopup(null)}
            popup={popup === 0}
            top={40}
          >
            The term tax code refers to a series of laws and regulations that
            outline the rights and responsibilities of the general public as
            they relate to taxation.
          </PopupHolder>
        </InputHolder>
      )}
      <InputHolder>
        <InputTitle>Please provide a link to your Privacy Policy:</InputTitle>
        <EulaInput
          urlPlaceholder
          value={policyLink}
          onChange={(e) => {
            setCompanyForm({
              ...companyForm,
              policyLink: e.target.value,
            })
          }}
          type="url"
          placeholder="e.g. www.yourwebsite.com/privacy-policy"
        />
        {!policyLinkValid && (
          <InputErrorMessage>
            Please enter a valid privacy policy link.
          </InputErrorMessage>
        )}
      </InputHolder>
    </QuestionWrapper>
  )
}

export default CompanyQuestions
