import styled from "styled-components";

export const PreviewWrapper = styled.div`
  padding: 16px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: 60%;

  @media (max-width: 765px) {
    width: 50%;
  }

  @media (max-width: 575px) {
    display: none;
    width: 100%;
  }
`;
export const PreviewTab = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;

  gap: 16px;
  align-self: stretch;
  background-color: #f7f7f7;
`;
export const TabContent = styled.div`
  width: 100%;
  height: max-content;
  padding: 16px 16px 16px 24px;
  background-color: #fffbea;
`;
export const LicensePreview = styled.h2`
  color: #7e7e7e;
  font-size: 18px;
  font-family: Karla;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;

  margin: 16px 0px 32px 0px;
`;

export const PreviewContetText = styled.p`
  margin: ${(props) => props.margin};
  color: #3b3b3b;
  font-size: 16px;
  font-family: Karla;
  font-style: normal;
  line-height: 26px;
  font-weight: ${({ weight }) => (weight ? `${weight}` : "400")};
`;
export const PreviewContetTitle = styled.p`
  margin: ${(props) => props.margin};
  color: #3b3b3b;
  font-size: 18px;
  font-family: Karla;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;
export const TabSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }
`;
