import styled from "styled-components";

export const TabText = styled.p`
  margin: ${(props) => props.margin};
  color: #3b3b3b;
  font-size: 16px;
  font-family: Karla;
  font-weight: 400;
  line-height: 26px;
`;
export const OrderedList = styled.ol`
  margin: 0;
  padding: 0;
  color: #3b3b3b;
  font-size: 16px;
  font-family: Karla;
  font-weight: 400;
  line-height: 26px;

  li {
    color: #3b3b3b;

    &::before {
      color: #3b3b3b; /* Change this to the desired color */
      font-weight: 400;
    }
  }
`;
