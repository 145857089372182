import styled from "styled-components"

export const AboutEulaWrapper = styled.div`
  border-radius: 0px 100px 0px 0px;
  background: var(--blue-100, #035388);
`
export const AboutEulaHolder = styled.div`
  margin: 128px 0px;

  @media (max-width: 1050px) {
    margin: 64px 0px;
  }
`

export const FLexBox = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  gap: 120px;

  @media (max-width: 1024px) {
    gap: 36px;

    margin-top: 32px;
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    margin-top: 32px;
    flex-direction: column;

    & > img {
      height: 450px;
    }
  }
`
export const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 34px;

  @media (max-width: 1024px) {
    flex-direction: column;
    order: 2;

    margin-left: 10px;
    margin-right: 10px;
  }
`

export const ParagraphHolder = styled.div`
  max-width: 544px;

  @media (max-width: 1024px) {
    max-width: max-content;
  }

  @media (max-width: 375px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`
