import styled from "styled-components";
import Grid from "../../images/Grid3.png";

export const UseEulaWrapper = styled.div`
  padding-top: 128px;
  padding-bottom: 128px;
  background-image: url(${Grid});
  background-size: contain;

  @media (max-width: 912px) {
    background-size: cover;
  }
  @media (max-width: 765px) {
    background: #f7f7f7;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;
export const EulaUseFlexBox = styled.div`
  margin-top: 64px;
  margin-bottom: 96px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 7%;

  @media (max-width: 1210px) {
    margin-bottom: 64px;
    margin-right: 0px;
  }

  @media (max-width: 765px) {
    margin-top: 32px;
    margin-right: 0px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;
export const EulaUseFlexItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 32px;

  @media (max-width: 1210px) {
    & > img {
      min-height: 200px;
      min-width: 200px;
    }
  }
`;
export const ButtonHolder = styled.div`
  display: none;
  margin: 0 auto;
  width: 60%;

  @media (max-width: 765px) {
    display: block;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`;
