import styled from "styled-components";

export const StepTwoWrapper = styled.div`
  display: none;
  flex-direction: column;
  max-height: 100vh;
  gap: 16px;
  width: 100%;
  border-radius: 8px;
  width: 100%;
  margin-left: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }

  @media (max-width: 575px) {
    display: flex;
  }

  @media (max-width: 575px) {
    margin: 0;
    line-height: 25px;
    font-size: 12px;
  }
`;
export const StepTwoTitle = styled.div`
  color: #0b69a3;
  font-family: Karla;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.5px;
  max-width: 468px;

  @media (max-width: 765px) {
    font-size: 32px;
  }
  @media (max-width: 400px) {
    font-size: 28px;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  padding-top: 16px;
  border: 2px solid #f7f7f7;
  border-radius: 8px;
  background: #f7f7f7;
`;

export const CloseHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const IconHolder = styled.div`
  padding: 8px;
`;
export const Icon = styled.img`
  max-width: 70px;
  max-height: 90px;
`;
export const ItemHolder = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  gap: 32px;

  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
`;
export const Disclosure = styled.p`
  max-width: 600px;
  color: #7e7e7e;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 885px) {
    font-size: 12px;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 29px;
  align-self: stretch;
  border-radius: 8px;
  background: #0b69a3;
`;
export const BottomSectioTitle = styled.div`
  color: #f7f7f7;
  font-family: Karla;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
`;
export const HeartIcon = styled.span`
  color: red;
`;
export const BottomSectionText = styled.p`
  max-width: 400px;
  color: #f7f7f7;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`;

export const BottomFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  @media (max-width: 885px) {
    flex-direction: column;
    gap: 29px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
`;

export const IconFlex = styled.div`
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const LinkHolder = styled.div`
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  margin: 0 auto;
  width: 100%;

  color: #0b69a3;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 400px) {
    padding: 8px 35px;
    width: 80%;
  }
  @media (max-width: 360px) {
    padding: 8px 28px;
    font-size: 14px;
  }
`;
