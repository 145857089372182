import React, { useState } from "react"
import {
  LicensePreview,
  PreviewWrapper,
  TabSection,
} from "./preview-section.styled"
import PreviewTab from "./PreviewTab/preview-tab"
import Definition from "../PreviewSection/Tabs/definitions"
import EndUserAgreement from "./Tabs/end-user-agreement"
import SourcesAccess from "./Tabs/sources-access"
import Rights from "./Tabs/rights"
import Responsibilities from "./Tabs/responsibilities"
import LicenseCheck from "./Tabs/license-check"
import TermTermination from "./Tabs/term-termination"
import IntellectualProperty from "./Tabs/intellectual-property"
import PrivacyDataProtection from "./Tabs/privacy-data-protection"
import WarrantyLiability from "./Tabs/warranty-liability"
import Indemnification from "./Tabs/indemnification"
import Miscellaneous from "./Tabs/miscellaneous"

const PreviewSection = ({ EulaData }) => {
  const [openTab, setOpenTab] = useState(null)

  const handleTabClick = (tabId) => {
    if (openTab === tabId) {
      setOpenTab(null)
    } else {
      setOpenTab(tabId)
    }
  }

  return (
    <PreviewWrapper>
      <LicensePreview>Licence preview:</LicensePreview>
      <TabSection>
        <PreviewTab
          title="END USER LICENSE AGREEMENT"
          openTab={openTab === 0}
          onClick={() => handleTabClick(0)}
          component={<EndUserAgreement EulaData={EulaData} />}
        />
        <PreviewTab
          title="DEFINITIONS"
          openTab={openTab === 1}
          onClick={() => handleTabClick(1)}
          component={<Definition EulaData={EulaData} />}
        />
        <PreviewTab
          title="SOURCES, ACCESS, DELIVERY AND REQUIREMENTS"
          openTab={openTab === 2}
          onClick={() => handleTabClick(2)}
          component={<SourcesAccess EulaData={EulaData} />}
        />
        <PreviewTab
          title="RIGHTS GRANTED"
          openTab={openTab === 3}
          onClick={() => handleTabClick(3)}
          component={<Rights EulaData={EulaData} />}
        />
        <PreviewTab
          title="YOUR RESPONSIBILITIES"
          openTab={openTab === 4}
          onClick={() => handleTabClick(4)}
          component={<Responsibilities EulaData={EulaData} />}
        />
        <PreviewTab
          title="LICENSE CHECKS, UPDATES, AVAILABILITY AND DISCONTINUATION"
          openTab={openTab === 5}
          onClick={() => handleTabClick(5)}
          component={<LicenseCheck EulaData={EulaData} />}
        />
        <PreviewTab
          title="TERM AND TERMINATION"
          openTab={openTab === 6}
          onClick={() => handleTabClick(6)}
          component={<TermTermination EulaData={EulaData} />}
        />
        <PreviewTab
          title="INTELLECTUAL PROPERTY RIGHTS "
          openTab={openTab === 7}
          onClick={() => handleTabClick(7)}
          component={<IntellectualProperty EulaData={EulaData} />}
        />
        <PreviewTab
          title="PRIVACY AND DATA PROTECTION"
          openTab={openTab === 8}
          onClick={() => handleTabClick(8)}
          component={<PrivacyDataProtection EulaData={EulaData} />}
        />
        <PreviewTab
          title="WARRANTY AND LIABILITY"
          openTab={openTab === 9}
          onClick={() => handleTabClick(9)}
          component={<WarrantyLiability EulaData={EulaData} />}
        />
        <PreviewTab
          title="INDEMNIFICATION"
          openTab={openTab === 10}
          onClick={() => handleTabClick(10)}
          component={<Indemnification EulaData={EulaData} />}
        />
        <PreviewTab
          title="MISCELLANEOUS"
          openTab={openTab === 11}
          onClick={() => handleTabClick(11)}
          component={<Miscellaneous EulaData={EulaData} />}
        />
      </TabSection>
    </PreviewWrapper>
  )
}

export default PreviewSection
