import React from "react"
import { OrderedList } from "./tab.styles"

const Indemnification = ({ EulaData }) => {
  const { softwareForm } = EulaData
  const { softwareName } = softwareForm

  return (
    <div>
      <OrderedList>
        <li>
          Indemnification. You shall indemnify and hold Us harmless from and
          against all damages, losses, and expenses of any kind (including
          reasonable legal fees and costs) arose by any claim made by any third
          party (including, without limitation, any agency, public
          administrations, entities, etc.) against Us in connection with any of
          Your: (i) violation or breach of any term of this EULA; (ii) violation
          of any applicable Law, whether or not referenced herein; (iii)
          violation of any rights of any third party; (iv) use or misuse of the
          {!softwareName ? "[Software_Name]" : softwareName} Software.
        </li>
        <li>
          Remedies. You expressly agree and acknowledge that any violation of
          Your obligations pursuant to this EULA may cause Us irreparable harm
          and damage, which may not be recovered at law. Therefore, You agree
          that Our remedies for breach of this EULA may be in equity by way of
          injunctive relief, as well and any other relief available, whether in
          law or in equity.
        </li>
      </OrderedList>
    </div>
  )
}

export default Indemnification
