import React from "react"
import { FaqEulaWrapper, FaqContainer } from "./eula-faq.styled"
import {
  Container,
  MainTitle,
  Paragraph,
  SubTitle,
} from "../../shared/shared.styles"
import Accordion from "../../../Accordion/Accordion"

const questions = [
  {
    title: "What is Contained within a EULA?",
    content:
      "How does the EULA Generator Work? After answering a few simple EULA Generator questions, your policy will be automatically generated, downloaded, and ready to be published.",
  },
  {
    title: "Is a EULA Required by Law?",
    content:
      "A EULA is not required under the law, however, a EULA is essential to protect your software and prevent users from copying, selling, and distributing your app. A EULA can also let you limit your liability from any improper software use and provide information on warranties.",
  },
  {
    title: "Where Do I Display my EULA?",
    content:
      "EULA should be displayed in a prominent place in your app. Your users must be able to read and accept your EULA before they can download your app. A EULA is commonly placed in a menu near the Privacy Policy and Terms of Service.",
  },
  {
    title:
      "What benefits does having a clear and comprehensive EULA offer for my app?",
    content:
      "A well-crafted EULA serves as a crucial document for your app's users and your business. It helps establish guidelines for acceptable use, protects your intellectual property, and outlines user responsibilities. Additionally, it can limit your liability and provide essential information about warranties. Having a clear and comprehensive EULA not only fosters trust with your users but also helps mitigate legal risks for your business.",
  },
  {
    title: "What Should I Include in My EULA Regarding User Responsibilities?",
    content:
      "In your EULA, it's important to outline the responsibilities and obligations of the users regarding the use of your software. You can specify acceptable and unacceptable uses, guidelines for user conduct, and consequences for violating the agreement. Clear communication of these expectations helps to protect your rights and maintain a positive user experience.",
  },
  {
    title: "Where Can I Implement My Customized EULA Templates?",
    content:
      "Our EULA Generator provides tailored templates for your web, mobile, and desktop apps. Whether you're launching a new website, app, or software, our templates are versatile and can be seamlessly integrated into your platform. Ensure compliance and protect your rights across all your digital ventures with our comprehensive EULA solutions.",
  },
  {
    title: "How Often Should I Update My EULA?",
    content:
      "It's essential to regularly review and update your EULA to reflect any changes in your software, business practices, or legal requirements. As your app evolves and new features are added, your EULA may need adjustments to ensure it remains accurate and effective. Regular updates help maintain transparency with your users and keep your legal protections up to date.",
  },
  {
    title: "Is the generated EULA legally binding?",
    content:
      "While the EULA generated by our tool is drafted by a legal professional, it's essential to understand that it serves informational purposes only and should not be considered a substitute for personalized legal advice. Each software application and business scenario may have unique legal considerations, so we recommend consulting with a qualified attorney to ensure your EULA accurately reflects your specific needs and complies with relevant laws and regulations.",
  },
]

const EulaFaq = () => {
  return (
    <FaqEulaWrapper>
      <Container>
        <MainTitle margin="0 0 2rem">EULA Generator FAQ</MainTitle>
        <FaqContainer>
          <Accordion items={questions}></Accordion>
        </FaqContainer>
      </Container>
    </FaqEulaWrapper>
  )
}

export default EulaFaq
