import React from "react"
import "./GlobalCss.css"
import { Main, Navbar, Wrapper } from "./NewLayoutStyled"
import Header from "../../Header/index"
import Footer from "../../Footer/index"

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  )
}

export default Layout
