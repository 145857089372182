import React from "react"
import { PreviewContetText } from "../preview-section.styled"
import { OrderedList } from "./tab.styles"

const SourcesAccess = ({ EulaData }) => {
  const { softwareForm } = EulaData

  const {
    softwareName,
    softwareLink,
    accountRegistration,
    severalUsers,
    subscriptionPlan,
  } = softwareForm

  return (
    <div>
      <PreviewContetText margin="0px 0px 8px 0px">
        Authorized sources. You must lawfully acquire the{" "}
        {!softwareName ? "[Software_Name]" : softwareName}
        Software by purchasing, downloading or accessing it from Our website at
        {!softwareLink ? "[Software_Link]" : softwareLink} or from any of Our
        authorized reseller, partner or provider. In the latter case, You shall
        also comply with the terms and conditions of such resellers, partners or
        providers.
      </PreviewContetText>
      <OrderedList>
        <li>
          Access. Your access to the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software is granted{" "}
          {accountRegistration &&
            ` subject to an account registration to Our
          website at ${
            !softwareLink ? "[Software_Link]" : softwareLink
          }, as well
          as `}
          under the terms of this EULA{" "}
          {subscriptionPlan && "and the relevant Subscription Plan"}.
          {severalUsers &&
            ` In case You are allowed to have a Multi-User Account,
          You may allow Authorized Users to access the
          ${!softwareName ? "[Software_Name]" : softwareName} Software and the
          related Services within the limited number of licenses agreed
          ${
            subscriptionPlan &&
            "and/or allowed by the relevant Subscription Plan"
          }
          , provided in any case that such access shall be for the Authorized
          Users’ sole benefit and in full compliance with the terms herein. To
          such purpose, You shall: (i) identify the Authorized Users and assign,
          revoke or reassign them their account login information (i.e.,
          username and password) for the access to the
          ${!softwareName ? "[Software_Name]" : softwareName}
          Software and Services; (ii) control against any unauthorized access to
          the ${
            !softwareName ? "[Software_Name]" : softwareName
          } Software and/or
          to the related Services by the Authorized Users; (iii) be responsible
          for the Authorized Users’ compliance with the terms herein as well as
          for all activities that occur under their account or as a result of
          any Authorized Users’ access to the
          ${
            !softwareName ? "[Software_Name]" : softwareName
          } Software and to the
          Services.`}
        </li>
        <li>
          Requirements. Only the Software running on the hardware and in the
          environment specified in the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software’s
          documentation is unconditionally eligible for running the same, as
          well as for Our support and other services. You agree that You are
          solely responsible for these requirements, as well as for any needed
          changes, updates and fees.
        </li>
      </OrderedList>
    </div>
  )
}

export default SourcesAccess
