import React from "react"
import { OrderedList } from "./tab.styles"

const LicenseCheck = ({ EulaData }) => {
  const { CompanyForm, softwareForm } = EulaData

  const {} = CompanyForm

  const { softwareName, licenseChecks, automaticUpdate } = softwareForm

  return (
    <div>
      <OrderedList>
        {licenseChecks && (
          <li>
            License Checks. You expressly acknowledge and accept that the{" "}
            {!softwareName ? "[Software_Name]" : softwareName} Software need
            license checks before or during its execution. Therefore, You are
            strictly prohibited from alter or modify the{" "}
            {!softwareName ? "[Software_Name]" : softwareName} Software
            behaviour or functionality (e.g., prevent the software from
            connecting to the internet to perform such checks, etc.).
          </li>
        )}
        <li>
          Updates. We, in Our sole discretion, reserve the right to add or
          remove tools, utilities, improvements, Third-Party Software, features
          or functions, or to provide programming fixes, updates and upgrades,
          to the {!softwareName ? "[Software_Name]" : softwareName} Software in
          order to improve and enhance the features and performance thereof
          and/or according to Our discontinuation policy.{" "}
          {automaticUpdate &&
            `The 
          ${!softwareName ? "[Software_Name]" : softwareName} Software contains
          automatic update technology, a feature used to provide maintenance
          (such as a bug fix, patches, enhanced functions, missing plug-ins, and
          new versions) and, by installing the same
          ${
            !softwareName ? "[Software_Name]" : softwareName
          } Software initially,
          You consent to the transmission of standard computer information and
          the automatic downloading and installation of maintenance Software.`}
          Given the preceding, You expressly acknowledge and agree that: (i) We
          have no obligation to make available and/or provide any updates; (ii)
          in case We provide any updates, this EULA shall automatically apply
          thereto, unless We provide other terms along with such updates; (iii)
          We disclaim any liability in case any updates result in an
          unavailability, deficiency or incompatibility of any Third-Party
          Software (and/or of any other pre-existing feature or function) with
          the updated Software.
        </li>
        <li>
          Availability. We will use commercially reasonable efforts to ensure
          the availability of the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software for Our
          customers. Regardless, You acknowledge and agree that We have no
          control or liability over the availability of the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software on a
          continuous or uninterrupted basis, so that from time to time the same
          may be inaccessible or inoperable, including, without limitation, for:
          (i) equipment malfunctions, periodic maintenance procedures or
          repairs; (ii) Force Majeure; (iii) limitations due to Your particular
          device or hardware; (iv) unavailability, deficiency or incompatibility
          of any Third-Party Software with the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software for any
          reason (e.g., third-party’s or Our discontinuation policy,
          incompatibilities between any updates and a Third-Party Software,
          etc.); (v) Your violation of any relevant provision of this EULA.
          [SubscriptionPlan_YES: Any unavailability of the{" "}
          {!softwareName ? "[Software_Name]" : softwareName}
          Software under this Sect. 5.3) shall not excuse You from Your
          obligation to make timely payment(s) under the applicable Subscription
          Plan.]
        </li>
        <li>
          Discontinuation policy. You expressly acknowledge and accept that the
          {!softwareName ? "[Software_Name]" : softwareName} Software is subject
          to Our discontinuation policy and, therefore, We reserve the right –
          without Our liability in such respect towards You or any other third
          party – to discontinue any
          {!softwareName ? "[Software_Name]" : softwareName} Software’s feature,
          content or service (including, without limitation, access to technical
          support, access to Third-Party Software, etc.), in accordance with
          such discontinuation policy. The preceding applies also to any
          Third-Party Software, which is subject to such third party’s
          discontinuation policy, without Our liability towards You or any other
          third party in case any updates result in an unavailability,
          deficiency or incompatibility of any pre-existing Third-Party Software
          with the updated Software.
        </li>
      </OrderedList>
    </div>
  )
}

export default LicenseCheck
