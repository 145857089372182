import styled from "styled-components";

export const PreviewTabWrapper = styled.div``;
export const PreviewTabContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  background-color: #f7f7f7;
  cursor: pointer;
`;
