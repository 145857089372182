import styled from "styled-components"

export const QuestionWrapper = styled.form`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding-right: 16px;
  padding-bottom: 100px;

  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }

  @media (max-width: 575px) {
    display: ${({ show }) => (show ? "flex" : "none")};
  }
`

export const CheckBoxItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #7e7e7e;
  font-family: Karla;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
`
export const QuestionIcon = styled.img`
  margin-left: 12px;
  max-height: 16px;
  max-width: 16px;
  cursor: pointer;
`
export const PopupHolder = styled.div`
  width: 100%;
  max-height: 150px;

  overflow-y: auto;
  display: ${({ popup }) => (popup ? "block" : "none")};
  z-index: 10;
  position: absolute;
  top: ${({ top }) => top && `${top}%`};
  background-color: white;
  border: 1px solid #0b69a3;
  border-radius: 8px;
  padding: 10px;
  color: #0b69a3;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(weight) => (weight ? `${weight}` : "400")};
  line-height: 26px;
  @media (max-width: 765px) {
    max-height: auto;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }
`
