import React, { useState } from "react"
import {
  CloseHolder,
  StepTwoTitle,
  StepTwoWrapper,
  IconHolder,
  Icon,
  Item,
  ItemHolder,
  TopSection,
  Disclosure,
  BottomSection,
  BottomSectioTitle,
  HeartIcon,
  BottomSectionText,
  BottomFlex,
  IconFlex,
  LinkHolder,
  RightSection,
  LoaderSpinner,
} from "./step2.styled"
import { EulaText, PreviewTitles } from "../../shared/shared.styles"
import CloseIcon from "../../images/closeIcon.svg"
import EulaDocx from "../../images/EulaDocx.svg"
import EulaTxt from "../../images/EulaTxt.svg"
import EaulaEmail from "../../images/EulaEmail.svg"
import LinkedIn from "../../images/linkedin.svg"
import Twitter from "../../images/twitter.svg"
import Facebook from "../../images/facebook.svg"
import Reddit from "../../images/reddit.svg"
import { GenerateEULA } from "../../helper/GenerateEULA"
import { GetDocx, GetTxt, SendEmail } from "../../helper/FetchEULA"
import EmailModal from "./emailModal"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share"
import { Button } from "../../shared/button"
import { navigate } from "gatsby"
const Step2 = ({ setStepsCompleted, EulaData }) => {
  const eula = GenerateEULA(EulaData)
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState("")

  const [loadingDocx, setLoadingDocx] = useState(false)
  const [loadingTxt, setLoadingTxt] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  function ResolveEmailSending() {
    SendEmail(
      eula,
      setLoadingEmail,
      setEmailSent,
      setModalOpen,
      email,
      setEmail
    )
  }

  const url = "https://licensespring.com/eula-generator/"
  const description = `Excited to share that I just created a comprehensive End-User License Agreement (EULA) using LicenseSpring's EULA Generator! If you're a developer or business owner, I highly recommend checking it out at ${url}`
  const title = "LicenseSpring's EULA Generator"

  return (
    <StepTwoWrapper>
      <TopSection>
        <CloseHolder>
          <PreviewTitles>It’s DONE!</PreviewTitles>
          {/* <IconHolder
            onClick={() => {
              setStepsCompleted(1)
            }}
          >
            <img src={CloseIcon} />
          </IconHolder> */}
        </CloseHolder>
        <StepTwoTitle>Thank you for using our EULA Generator!</StepTwoTitle>
        <ItemHolder>
          <Item
            onClick={() => {
              setLoadingDocx(true)
              GetDocx(eula, setLoadingDocx)
            }}
          >
            <Icon src={EulaDocx} />
            <EulaText center>
              {loadingDocx ? <LoaderSpinner /> : "Download as .docx"}
            </EulaText>
          </Item>
          <Item
            onClick={() => {
              setLoadingTxt(true)
              GetTxt(eula, setLoadingTxt)
            }}
          >
            <Icon src={EulaTxt} />
            <EulaText center>
              {loadingTxt ? <LoaderSpinner /> : "Download as .txt"}{" "}
            </EulaText>
          </Item>
          <Item
            onClick={() => {
              if (!modalOpen) setModalOpen(true)
            }}
          >
            <Icon src={EaulaEmail} />
            <EulaText center>
              {loadingEmail ? (
                <LoaderSpinner />
              ) : emailSent ? (
                "Email Sent!"
              ) : (
                "Send as Email"
              )}
            </EulaText>
            <EmailModal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              ResolveEmailSending={ResolveEmailSending}
              setLoadingEmail={setLoadingEmail}
              loadingEmail={loadingEmail}
              email={email}
              setEmail={setEmail}
              emailSent={emailSent}
            />
          </Item>
        </ItemHolder>
        <EulaText maxw={502}>
          *If you need to make any revisions to the file, you can easily do so
          by changing your answers on our EULA generator. Simply revisit the
          platform, make the necessary adjustments, and generate a new version
          of your EULA.
        </EulaText>
        <Disclosure>
          <strong>Disclosure:</strong>
          <br />
          Data entered into the generator is stored locally on your device as
          temporary data. Once your session ends, this temporary data is
          automatically deleted.
        </Disclosure>
        <Button
          type="dark"
          shadow
          width={100}
          maxw={320}
          onClick={() => {
            navigate("/")
          }}
        >
          Return to Home Page
        </Button>
      </TopSection>
      <BottomSection>
        <BottomSectioTitle>
          Sharing is caring! <HeartIcon>♥</HeartIcon>
        </BottomSectioTitle>
        <BottomFlex>
          <BottomSectionText>
            Share your experience with others and support us. We value your
            feedback and are here to assist you.
          </BottomSectionText>
          <RightSection>
            <IconFlex>
              <LinkedinShareButton
                url={url}
                title={title}
                summary={description}
              >
                <img src={LinkedIn} />
              </LinkedinShareButton>
              <TwitterShareButton url={url} title={description}>
                <img src={Twitter} />
              </TwitterShareButton>

              <FacebookShareButton
                url={url}
                quote={description}
                windowWidth={660}
                windowHeight={460}
                onShareWindowClose={() => console.log("Window closed!")}
              >
                <img src={Facebook} />
              </FacebookShareButton>

              <RedditShareButton
                url={description}
                title={title}
                windowWidth={660}
                windowHeight={460}
              >
                <img src={Reddit} />
              </RedditShareButton>
            </IconFlex>
            <LinkHolder>{url}</LinkHolder>
          </RightSection>
        </BottomFlex>
      </BottomSection>
    </StepTwoWrapper>
  )
}

export default Step2
