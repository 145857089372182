import styled from "styled-components"

export const EulaGeneratorWrapper = styled.div`
  display: flex;
  margin: 16px; // remove this margin to remove scrollbar from site affect
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: auto;
`

export const InnerHolderEula = styled.div`
  display: flex;
  flex: 1;
`
