import React from "react"
import { ImageStepZero, StepWrapper } from "./step0.stlyled"
import { EulaText, PreviewTitles } from "../../shared/shared.styles"
import CTA from "../../images/CTA.png"
import LSLogo from "../../images/LSLogo.png"
import { Button } from "../../shared/button"

const Step0 = ({ setStepsCompleted }) => {
  return (
    <StepWrapper>
      <ImageStepZero>
        <img src={CTA} alt="CTA" />
      </ImageStepZero>
      <img src={LSLogo} alt="LS logo" />
      <PreviewTitles center>Free Eula generator</PreviewTitles>
      <EulaText center maxw={332}>
        To help you build your custom EULA we will need to you to answer certain
        quiestion. To proceed click start!
      </EulaText>
      <Button
        type="light"
        shadow
        width={100}
        maxw={200}
        margin="0px 0px 30px 0px"
        onClick={() => {
          setStepsCompleted(1)
        }}
      >
        Start
      </Button>
    </StepWrapper>
  )
}

export default Step0
