import styled from "styled-components"

export const StepOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
  padding: 16px;

  gap: 30px;
  width: 40%;
  border-radius: 8px;
  border: 2px solid #f7f7f7;
  background: ${(props) => (props.mobile ? "white" : "#f7f7f7")};
  position: relative;

  @media (max-width: 765px) {
    width: 50%;
  }

  @media (max-width: 575px) {
    padding-top: 60px; // distance between top nav buttons and question box
    padding-bottom: 74px; // should be height of button navbar
    width: 100%;
  }
`

export const MiddleSection = styled.div`
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`

export const TopComponent = styled.div`
  display: none;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  @media (max-width: 575px) {
    display: flex;
  }
`

export const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-top: 16px;

  background: ${({ active }) =>
    active ? "linear-gradient(225deg, #cdeefe 0%, #e5e5e5 100%)" : "#fff"};
  color: #0b69a3;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
`

export const Edit = styled.div`
  border-bottom: ${({ active }) => (active ? "2px solid #0B69A3" : "none")};
  padding-bottom: 16px;
`
export const PreviewButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-top: 16px;
  background: ${({ active }) =>
    active ? "linear-gradient(225deg, #cdeefe 0%, #e5e5e5 100%)" : "#fff"};
  color: #0b69a3;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
`

export const BottomComponent = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  position: absolute;
  gap: 16px;
  padding: 16px;
  max-height: 74px;
  width: 100%;
  left: 0;
  bottom: 0;
`
