import React from "react"
import { OrderedList, TabText } from "./tab.styles"

const Definitions = ({ EulaData }) => {
  const { softwareForm } = EulaData

  const {
    softwareName,
    softwareDescription,
    softwareLink,
    accountRegistration,
    severalUsers,
    subscriptionPlan,
  } = softwareForm

  return (
    <div>
      <TabText margin="0px 0px 8px 0px">
        The following capitalized terms shall have the meanings set forth below,
        provided that the singular includes the plural and vice-versa, and any
        gender shall include the other genders:
      </TabText>
      <OrderedList>
        {severalUsers && (
          <li>
            “Authorized Users” means, singularly and collectively, in case of
            Multi-User Account, each and all the users that You authorized to
            access and use the{" "}
            {!softwareName ? "[Software_Name]" : softwareName} Software and/or
            the related Services, according to the number of Authorized Users
            allowed herein {!softwareName ? "[Software_Name]" : softwareName}
            {subscriptionPlan && " or by the applicable Subscription Plan"}.
          </li>
        )}
        <li>
          “Data” means any information, text, message, software, picture, sound,
          video, music and any library, data, content, or material transmitted
          or conveyed through the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software and/or the
          related Services.
        </li>
        <li>
          “Commencement Date” means any of the following moments, whichever
          occurs first: (i) Your express acceptance of this EULA; (ii) Your
          download and/or installation of the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software;
          {accountRegistration &&
            ` (iii) Your registration of an account on the
          ${
            !softwareName ? "[Software_Name]" : softwareName
          } Software website at ${
              !softwareLink ? "[Software_Link]" : softwareLink
            };`}{" "}
          {accountRegistration ? "(iv)" : "(iii)"} Your acceptance of any valid
          communication with Us or any of Our authorized representative that
          refers to this EULA.
        </li>
        <li>
          “Force Majeure” means any act, event or cause, except in relation to
          obligations to make payments, beyond the reasonable control of the
          Party affected by that force majeure including, without limitation,
          any act of God or any public enemy, fire, flood, explosion, landslide,
          epidemic, breakdown of or damage to plant, equipment or facilities,
          inability to obtain or unavailability of or damage to materials,
          ingredients or supplies, strikes, labor disputes, war, sabotage, riot,
          insurrection, civil commotion, national emergency and martial law,
          expropriation, restraint, prohibition, embargo, decree or order of any
          government, governmental authority or court.
        </li>
        <li>
          “Intellectual Property Rights” or “IP Rights” means all intellectual
          property and other similar proprietary rights in any jurisdiction,
          whether owned or held for use under license, whether registered or
          unregistered, including such rights in and to: trademarks, logos,
          trade names, patents and patent applications, utility models,
          inventions, discoveries, writings and other works of authorship, trade
          secrets, information that is not generally known to the public or that
          constitutes a trade secret under any applicable Law, Software
          (including, without limitation, data files, source code, object code,
          application programming interfaces, databases and other
          software-related specifications and documentation), advertising and
          promotional materials, as well as claims, causes of action and
          defenses relating to the enforcement of any of the foregoing. In each
          case above, “IP Rights” include also any registrations of,
          applications to register, and renewals and extensions of, any of the
          foregoing before or by any governmental authority in any jurisdiction.
        </li>
        <li>
          “Law” means, collectively and indistinctly, any applicable local,
          state, national, and international laws, by-laws, rules, regulation,
          circulars, notifications, ordinances, protocols, codes, guidelines,
          policies, notices, directions, orders, decrees, judgments of courts,
          or other requirements of any governmental authority.
        </li>
        {severalUsers && (
          <li>
            “Multi-User Account” means an account that allows You to authorize
            Authorized Users to access the{" "}
            {!softwareName ? "[Software_Name]" : softwareName} Software and the
            related Services, within the license limits set out in the
            applicable Subscription Plan.
          </li>
        )}

        <li>
          “Party” means either You or Us, while “Parties” means, collectively,
          both You and Us.
        </li>
        <li>
          “{!softwareName ? "[Software_Name]" : softwareName} Software” means,
          collectively and indistinctly, Our “
          {!softwareName ? "[Software_Name]" : softwareName}” proprietary
          Software and any Third-Party Software integrated thereto, which is
          made available to You according to this EULA{" "}
          {subscriptionPlan && "and a purchased Subscription Plan"}. In
          particular, the said “
          {!softwareName ? "[Software_Name]" : softwareName}” Software has the
          following features:{" "}
          {!softwareDescription
            ? "[Software_Description]"
            : softwareDescription}
          . “Personal Data” means any Data referred to an identified or
          identifiable natural person. “Personal Data Protection Laws” mean any
          applicable Law regarding the protection of Personal Data. “Software”
          means, collectively and indistinctly, any software, including, without
          limitation, computer software, documentation (including, without
          limitation, standard product documentation, user and technical
          documentation, further documents that describe the standard
          functionalities thereof, etc.), tool, application, application
          programming interface (API), development libraries, user interfaces,
          component, user interface, appearance (including, without limitation,
          images, designs, fonts, etc.), code (including, without limitation,
          assemblers, applets, compilers, source code, source code listings,
          object code, plug-in, etc.), as well as any fixes, patches, updates or
          upgrades to such items.
        </li>
        <li>
          “Services” means, collectively and indistinctly, the services
          (including, without limitation, SaaS, internet-based service, etc.)
          made available to You according to this EULA{" "}
          {subscriptionPlan && "and the Subscription Plan that You purchased"} .
          To such purpose, “Services” include also the access to any
          Software_Name Software’s website platform and any associated feature
          or service, as well as any Software or Data to which is provided
          access to You as part of the
          {!softwareName ? "[Software_Name]" : softwareName} Software and/or the
          same Services.
        </li>
        {subscriptionPlan && (
          <li>
            “Subscription Plan” means the subscription plan purchased by You.
            “Subscription Plan” includes also any related purchasing order and
            any special condition therein agreed upon between the Parties.
          </li>
        )}

        <li>
          “Third-Party Software” means, collectively and indistinctly, any
          Software developed, distributed or sold by an entity other than the
          Parties.
        </li>
      </OrderedList>
    </div>
  )
}

export default Definitions
