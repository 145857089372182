import styled from "styled-components"
import Grid from "../../images/Grid.png"

export const TemplateEulaWrapper = styled.div`
  background-image: url(${Grid});
  background-color: #0b69a3;
  padding-top: 128px;
  padding-bottom: 128px;

  @media (max-width: 980px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`
export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media (max-width: 980px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 56px;
    padding-bottom: 56px;
  }
`
export const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`
