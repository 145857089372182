import React, { useState } from "react"
import { EulaGeneratorWrapper, InnerHolderEula } from "./eula-generator.styled"
import { GeneratorContainer } from "../../shared/shared.styles"
import Step0 from "../Step 0/step0"
import PreviewSection from "../PreviewSection/preview-section"
import Step1 from "../step 1/step1"
import Step2 from "../step 2/step2"

const EulaGenerator = ({ stepsCompleted, setStepsCompleted }) => {
  //CompanyFormStates
  const [companyForm, setCompanyForm] = useState({
    isCompany: false,
    companyName: "",
    registeredAdress: "",
    businessRegistrationNumber: "",
    commercialScope: true,
    taxCodeToggled: false,
    taxCode: "",
    policyLink: "",
  })
  const [softwareForm, setSoftwareForm] = useState({
    softwareName: "",
    softwareLink: "",
    softwareDescription: "",
    licenseChecks: false,
    accountRegistration: false,
    severalUsers: false,
    numberOfDevices: "",
    automaticUpdate: false,
    trialPeriod: false,
    trialDuration: 0,
    subscriptionPlan: false,
    subscriptionExpireNotice: "",
  })
  const [privacyForm, setPrivacyForm] = useState({
    effectiveDate: "",
    applicableCountries: {},
    relevantCourt: "",
    fullRegisteredAddress: "",
    monetaryCapSelected: false,
    monetaryCap: "",
    dataProcessingLink: "",
    dataProcessing: false,
  })

  const EulaData = {
    CompanyForm: companyForm,
    softwareForm: softwareForm,
    privacyForm: privacyForm,
  }

  return (
    <>
      <EulaGeneratorWrapper>
        <GeneratorContainer>
          <InnerHolderEula>
            {stepsCompleted === 0 && (
              <Step0 setStepsCompleted={setStepsCompleted} />
            )}
            {stepsCompleted === 1 && (
              <Step1
                EulaData={EulaData}
                companyForm={companyForm}
                setCompanyForm={setCompanyForm}
                softwareForm={softwareForm}
                setSoftwareForm={setSoftwareForm}
                privacyForm={privacyForm}
                setPrivacyForm={setPrivacyForm}
                setStepsCompleted={setStepsCompleted}
                stepsCompleted={stepsCompleted}
              />
            )}
            {stepsCompleted === 2 ? (
              <Step2
                setStepsCompleted={setStepsCompleted}
                EulaData={EulaData}
              />
            ) : (
              <PreviewSection EulaData={EulaData} />
            )}
          </InnerHolderEula>
        </GeneratorContainer>
      </EulaGeneratorWrapper>
    </>
  )
}

export default EulaGenerator
