import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CloseIcon from "../../images/closeIcon.svg"
import { IconHolder } from "./step2.styled"
const ModalBackground = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  margin: 15% auto;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 540px;
`

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin: 10px 0 20px 0;
  border: none;
  background: #f1f1f1;
`

const Button = styled.button`
  background-color: #0b69a3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  min-width: max-content;
  max-width: max-content;
  margin-left: auto;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const EmailModal = ({
  modalOpen,
  setModalOpen,
  ResolveEmailSending,
  setLoadingEmail,
  loadingEmail,
  email,
  setEmail,
  emailSent,
}) => {
  useEffect(() => {
    console.log("Modal Open", modalOpen)
  }, [modalOpen])
  return (
    <div>
      <ModalBackground show={modalOpen}>
        <ModalContent>
          <Row>
            <h2>Enter your email</h2>
            <IconHolder
              onClick={() => {
                setModalOpen(false)
              }}
            >
              <img
                src={CloseIcon}
                onClick={() => {
                  setModalOpen(false)
                }}
              />
            </IconHolder>
          </Row>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={
              typeof emailSent === "string" ? emailSent : "example@gmail.com"
            }
          />
          <Button
            onClick={() => {
              setLoadingEmail(true)
              ResolveEmailSending()
            }}
          >
            {loadingEmail ? "Sending..." : "Send Email"}
          </Button>
        </ModalContent>
      </ModalBackground>
    </div>
  )
}

export default EmailModal
