import styled from "styled-components";

export const ProgressBarHolder = styled.div`
  position: fixed;
  z-index: 10;
  top: 0%;
  height: 8px;
  width: 100%;
  background-color: #7e7e7e;
`;
export const ProgressBarFilled = styled.div`
  height: 100%;
  width: 5%; /* Initial value of 5% */
  width: ${(props) => (props.step === 0 ? "5%" : `${(props.step / 2) * 100}%`)};
  background-color: #13b0fc;
  transition: width 0.5s ease;
`;
