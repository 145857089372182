import styled from "styled-components";

export const Button = styled.button`
  margin: ${(props) => props.margin};
  display: flex;
  padding: 18px 32px;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: 10px;
  border-radius: 8px;
  background: ${({ type }) =>
    type === "light"
      ? "#13B0FC"
      : type === "gradiant"
      ? "linear-gradient(180deg, #13B0FC 0%, #035388 100%)"
      : type === "dark"
      ? "#035388"
      : type === "white"
      ? "#fff"
      : type === "cream"
      ? "#FFF3C4"
      : type === "yellow" && "#F7C948"};

  box-shadow: ${(props) =>
    props.shadow
      ? " 0px 8px 8px 0px rgba(43, 176, 237, 0.25)"
      : props.shadowSecondary
      ? "0px 8px 8px 0px rgba(203, 122, 0, 0.25)"
      : props.shadowTrinary
      ? "0px 0px 55px 0px rgba(255, 255, 255, 0.89)"
      : "none"};

  color: ${(props) => (props.color ? props.color : "#fff")};
  text-align: center;
  font-size: 24px;
  font-family: Karla;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: ${(props) => props.fullWidth && "100%"};
  width: ${({ width }) => width && `${width}%`};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};

  @media (max-width: 715px) {
    max-width: ${(props) => props.newmaxw && `${props.newmaxw}px`};
  }
  @media (max-width: 575px) {
    max-width: ${(props) => props.oldmaxw && `${props.maxw}px`};
  }

  @media (max-width: 375px) {
    width: ${(props) => props.mobile && "100%"};
  }

  &:disabled {
    background-color: #cccccc;
    color: #999999;
    border: 1px solid #cccccc;
    cursor: not-allowed;
    box-shadow: none;
  }
`;
