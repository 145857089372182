import styled from "styled-components"

export const FaqEulaWrapper = styled.div`
  padding-top: 128px;
  padding-bottom: 128px;

  @media (max-width: 980px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

export const FlexWrapperFaq = styled.div`
  display: flex;
  padding-top: 64px;
  padding-bottom: 64px;
  flex-direction: column;
  gap: 24px;
`
export const FlexHolderFaq = styled.div`
  display: flex;
  justify-content: center;
  gap: 56px;

  @media (max-width: 1024px) {
    gap: 24px;
  }
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const DisclaimerFaq = styled.p`
  color: #9e9e9e;
  text-align: center;
  font-size: 16px;
  font-family: Karla;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`
export const FaqContainer = styled.div`
  width: 80%;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`
