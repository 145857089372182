export function GenerateEULA(EulaData) {
  const { CompanyForm, softwareForm, privacyForm } = EulaData
  const {
    softwareName,
    softwareDescription,
    softwareLink,
    accountRegistration,
    severalUsers,
    subscriptionPlan,
    numberOfDevices,
    licenseChecks,
    automaticUpdate,
    subscriptionExpireNotice,
    trialDuration,
  } = softwareForm
  const {
    effectiveDate,
    dataProcessing,
    dataProcessingLink,
    policyLink,
    monetaryCap,
    applicableCountries,
  } = privacyForm
  const {
    isCompany,
    companyName,
    registeredAdress,
    businessRegistrationNumber,
    taxCode,
    commercialScope,
  } = CompanyForm

  const FinalEula = []
  FinalEula.push({
    title: "END USER LICENSE AGREEMENT",
    paragraphType: ["", "", ""],
    paragraph: [
      `Effective Date: ${effectiveDate}`,
      `This end user license agreement (hereinafter referred to as the “EULA”)
                constitute a legal and binding agreement between
               ${companyName},
                ${
                  !isCompany
                    ? `with
                place of residence at ${registeredAdress}
                ${!taxCode ? "" : `, Tax code no. ${taxCode}`},`
                    : `with registered office at
                    ${registeredAdress}, business registration no. ${businessRegistrationNumber} ,
                  including its subsidiaries, affiliates and contractors acting on its
                  behalf`
                }
                (hereinafter collectively referred to as “Us”, “We”, or “Our”) and any
                person or entity and its heirs, agents, successors and assigns that have
                purchased, or is authorized to, access Our
                ${softwareName} Software (hereinafter
                singularly and collectively referred to as “You” or “Your”).`.replace(
        /\s+/g,
        " "
      ),
      `IMPORTANT NOTICE: Your continued use of the
        ${softwareName} Software constitutes
        Your expressed acceptance of, and agreement to be bound by, this EULA,
        as in force from time to time. Therefore, You understand and acknowledge
        that by downloading, accessing, installing or using the
        ${softwareName} Software You agree to
        be bound by the terms of this EULA. If You do not accept such terms in
        their entirety, You must not download, access, install or use the
        ${softwareName} Software. If You
        agree to this EULA on behalf of an entity, or in connection with
        providing or receiving services on behalf of an entity, You represent
        and warrant that You have the authority to bind that entity to this
        EULA. In such an event, “You” and “Your” will refer and apply to that
        entity or agency.`.replace(/\s+/g, " "),
    ],
  })
  FinalEula.push({
    title: "DEFINITIONS",
    paragraphType: [
      "",
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
    ],
    paragraph: [
      `The following capitalized terms shall have the meanings set forth below,
        provided that the singular includes the plural and vice-versa, and any
        gender shall include the other genders:`.replace(/\s+/g, " "),
      severalUsers &&
        `“Authorized Users” means, singularly and collectively, in case of
        Multi-User Account, each and all the users that You authorized to
        access and use the
        ${softwareName} Software and/or
        the related Services, according to the number of Authorized Users
        allowed herein ${softwareName}
        ${
          subscriptionPlan && " or by the applicable Subscription Plan"
        }.`.replace(/\s+/g, " "),
      `“Data” means any information, text, message, software, picture, sound,
          video, music and any library, data, content, or material transmitted
          or conveyed through the
          ${softwareName} Software and/or the
          related Services.
        `.replace(/\s+/g, " "),
      `
        “Commencement Date” means any of the following moments, whichever
          occurs first: (i) Your express acceptance of this EULA; (ii) Your
          download and/or installation of the
          ${softwareName} Software;
          ${
            accountRegistration &&
            ` (iii) Your registration of an account on the
          ${softwareName} Software website at ${softwareLink};`
          }
          ${accountRegistration ? "(iv)" : "(iii)"} Your acceptance of any valid
          communication with Us or any of Our authorized representative that
          refers to this EULA.
        `.replace(/\s+/g, " "),
      `   “Force Majeure” means any act, event or cause, except in relation to
        obligations to make payments, beyond the reasonable control of the
        Party affected by that force majeure including, without limitation,
        any act of God or any public enemy, fire, flood, explosion, landslide,
        epidemic, breakdown of or damage to plant, equipment or facilities,
        inability to obtain or unavailability of or damage to materials,
        ingredients or supplies, strikes, labor disputes, war, sabotage, riot,
        insurrection, civil commotion, national emergency and martial law,
        expropriation, restraint, prohibition, embargo, decree or order of any
        government, governmental authority or court.`.replace(/\s+/g, " "),
      `“Intellectual Property Rights” or “IP Rights” means all intellectual
        property and other similar proprietary rights in any jurisdiction,
        whether owned or held for use under license, whether registered or
        unregistered, including such rights in and to: trademarks, logos,
        trade names, patents and patent applications, utility models,
        inventions, discoveries, writings and other works of authorship, trade
        secrets, information that is not generally known to the public or that
        constitutes a trade secret under any applicable Law, Software
        (including, without limitation, data files, source code, object code,
        application programming interfaces, databases and other
        software-related specifications and documentation), advertising and
        promotional materials, as well as claims, causes of action and
        defenses relating to the enforcement of any of the foregoing. In each
        case above, “IP Rights” include also any registrations of,
        applications to register, and renewals and extensions of, any of the
        foregoing before or by any governmental authority in any jurisdiction.`.replace(
        /\s+/g,
        " "
      ),
      `“Law” means, collectively and indistinctly, any applicable local,
        state, national, and international laws, by-laws, rules, regulation,
        circulars, notifications, ordinances, protocols, codes, guidelines,
        policies, notices, directions, orders, decrees, judgments of courts,
        or other requirements of any governmental authority.`.replace(
        /\s+/g,
        " "
      ),
      severalUsers &&
        `“Multi-User Account” means an account that allows You to authorize Authorized Users to access the ${softwareName} Software and the related Services, within the license limits set out in the applicable Subscription Plan.`.replace(
          /\s+/g,
          " "
        ),
      `“Party” means either You or Us, while “Parties” means, collectively,
        both You and Us.`.replace(/\s+/g, " "),
      `“${softwareName} Software” means,
        collectively and indistinctly, Our “
        ${softwareName}” proprietary
        Software and any Third-Party Software integrated thereto, which is
        made available to You according to this EULA
        ${subscriptionPlan && "and a purchased Subscription Plan"}. In
        particular, the said “
        ${softwareName}” Software has the
        following features:
        ${softwareDescription}
        . “Personal Data” means any Data referred to an identified or
        identifiable natural person. “Personal Data Protection Laws” mean any
        applicable Law regarding the protection of Personal Data. “Software”
        means, collectively and indistinctly, any software, including, without
        limitation, computer software, documentation (including, without
        limitation, standard product documentation, user and technical
        documentation, further documents that describe the standard
        functionalities thereof, etc.), tool, application, application
        programming interface (API), development libraries, user interfaces,
        component, user interface, appearance (including, without limitation,
        images, designs, fonts, etc.), code (including, without limitation,
        assemblers, applets, compilers, source code, source code listings,
        object code, plug-in, etc.), as well as any fixes, patches, updates or
        upgrades to such items.`.replace(/\s+/g, " "),
      `“Services” means, collectively and indistinctly, the services
        (including, without limitation, SaaS, internet-based service, etc.)
        made available to You according to this EULA
        ${subscriptionPlan && "and the Subscription Plan that You purchased"} .
        To such purpose, “Services” include also the access to any
        Software_Name Software’s website platform and any associated feature
        or service, as well as any Software or Data to which is provided
        access to You as part of the
        ${softwareName} Software and/or the
        same Services.`.replace(/\s+/g, " "),
      subscriptionPlan &&
        `“Subscription Plan” means the subscription plan purchased by You. “Subscription Plan” includes also any related purchasing order and any special condition therein agreed upon between the Parties.`,
      `“Third-Party Software” means, collectively and indistinctly, any
        Software developed, distributed or sold by an entity other than the
        Parties.`.replace(/\s+/g, " "),
    ],
  })
  FinalEula.push({
    title: "SOURCES, ACCESS, DELIVERY AND REQUIREMENTS",
    paragraphType: ["", "number", "number"],
    paragraph: [
      `Authorized sources. You must lawfully acquire the
    ${softwareName}
    Software by purchasing, downloading or accessing it from Our website at
    ${softwareLink} or from any of Our
    authorized reseller, partner or provider. In the latter case, You shall
    also comply with the terms and conditions of such resellers, partners or
    providers.`.replace(/\s+/g, " "),
      `Access. Your access to the
    ${softwareName} Software is granted
    ${
      accountRegistration &&
      ` subject to an account registration to Our
    website at ${softwareLink}, as well
    as `
    }
    under the terms of this EULA
    ${subscriptionPlan && "and the relevant Subscription Plan"}.
    ${
      severalUsers &&
      ` In case You are allowed to have a Multi-User Account,
    You may allow Authorized Users to access the
    ${softwareName} Software and the
    related Services within the limited number of licenses agreed
    ${subscriptionPlan && "and/or allowed by the relevant Subscription Plan"}
    , provided in any case that such access shall be for the Authorized
    Users’ sole benefit and in full compliance with the terms herein. To
    such purpose, You shall: (i) identify the Authorized Users and assign,
    revoke or reassign them their account login information (i.e.,
    username and password) for the access to the
    ${softwareName}
    Software and Services; (ii) control against any unauthorized access to
    the ${softwareName} Software and/or
    to the related Services by the Authorized Users; (iii) be responsible
    for the Authorized Users’ compliance with the terms herein as well as
    for all activities that occur under their account or as a result of
    any Authorized Users’ access to the
    ${softwareName} Software and to the
    Services.`
    }`.replace(/\s+/g, " "),
      `Requirements. Only the Software running on the hardware and in the
    environment specified in the
    ${softwareName} Software’s
    documentation is unconditionally eligible for running the same, as
    well as for Our support and other services. You agree that You are
    solely responsible for these requirements, as well as for any needed
    changes, updates and fees.`.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "RIGHTS GRANTED",
    paragraphType: ["number", "number", "number", "number", "number"],
    paragraph: [
      `Grant of license.
    ${subscriptionPlan && "Subject to the relevant Subscription Plan,"} You
    are granted a limited, worldwide, non-assignable, non-sublicensable,
    non-transferable and non-exclusive license to use the
    ${softwareName} Software according
    to the terms herein.`.replace(/\s+/g, " "),
      `Allowed devices. You are permitted to access and use the
    ${softwareName} Software by running
    only no. ${numberOfDevices}
    devices.
    ${
      severalUsers &&
      "In case of Multi-User Account, the number of devices is allowed according to the relevant Subscription Plan."
    }`.replace(/\s+/g, " "),
      `Scope of the license.
    ${
      commercialScope &&
      `You
    agree that You will use the
    ${softwareName} Software only in
    connection with Your own normal internal commercial use, and You will
    not – unless otherwise provided in this EULA
    ${subscriptionPlan && "or by the applicable Subscription Plan"} – sell,
    lease, re-distribute, provide managed service, or otherwise provide or
    make available, either directly or via other means, or otherwise, the
    ${softwareName} Software to any
    third party.`
    }`.replace(/\s+/g, " "),
      `Third-Party Software. You expressly acknowledge and accept that
    certain content, products and services available via the
    ${softwareName} Software may
    include Third-Party Software, as specified in the
    ${softwareName} Software’s
    documentation. All Third-Party Software is licensed to You under the
    terms of this EULA, unless We provide other terms along with the
    ${softwareName} Software’s documentation. To any Third-Party Software shall apply
    Sects. 9.1), 9.2), 9.3) and 9.4) below and, therefore, without
    limiting the foregoing, We are not responsible for examining or
    evaluating the content or accuracy of the Third-Party Software nor
    provide any warranty, express or implied, with respect to any
    Third-Party Software and expressly disclaim any warranty or condition
    of merchantability, fitness for a particular purpose, title, and
    non-infringement.`.replace(/\s+/g, " "),
      `Reservation of rights. We expressly reserve all rights not expressly
    granted to You in this EULA.`.replace(/\s+/g, " "),
    ],
  })
  FinalEula.push({
    title: "YOUR RESPONSIBILITIES",
    paragraphType: ["number", "number", "number", "number", "number", "number"],
    paragraph: [
      `General responsibilities. You are entirely responsible for the use of
    the ${softwareName} Software and,
    to such purpose, You shall not use, encourage, promote, facilitate,
    instruct (or induce others to do the foregoing on) the
    ${softwareName} Software for any
    activity that violates any applicable Law or to transmit, store,
    display, distribute or otherwise make available any Data that is
    illegal or that would encourage or constitute a criminal or civil
    liability under any Law.`.replace(/\s+/g, " "),
      `Data lawfulness. You undertake to have all the interest and title
    (including, without limitation, any related Intellectual Property
    Right) in and to the Data that You may transmit, store, display,
    distribute or otherwise make available through the
    ${softwareName} Software, and that You are the sole responsible for the accuracy,
    quality, integrity, legality, reliability, and appropriateness
    thereof. Without limiting the foregoing, You warrant and represent
    that such Data is fully compliant with any applicable Law. In no way
    whatsoever We will be responsible for any of such Data.`.replace(
        /\s+/g,
        " "
      ),
      dataProcessing &&
        `Personal Data. Without limiting the foregoing, in case any Data is a
    Personal Data, You appoint Us as “processor” of the said Personal
    Data in order to process them for the purpose of hosting, processing
    (including, without limitation, reformat, manipulate, etc.) at the
    purpose to provide You with the Services. In particular, by
    accepting this EULA, You: (i) warrant and represent that the said
    Personal Data are compliant with the applicable Personal Data
    Protection Laws (including, without limitation, Your obligation to
    use such Personal Data only to the limited extent that is necessary
    and relevant to the Services and for no other purpose unless You
    have obtained the prior express consent from the related data
    subject, etc.); (ii) accept to be bound also by Our Data Processing
    Agreement available at ${dataProcessingLink}, which shall be an integral part
    of this EULA and is incorporated herein by reference.`.replace(/\s+/g, " "),
      `Updates. In case the
    ${softwareName} Software needs any
    update as provided in Sect. 5.1) below, You expressly agree to install
    such updates as soon as they are available, bearing any and all the
    related costs, if any. You shall bear full risk of any loss or damage
    in case any updates are not properly and timely installed due to Your
    fault; We expressly disclaim any liability in such respect.`.replace(
        /\s+/g,
        " "
      ),
      `Export regulations compliance. You expressly acknowledge that You are
    not an entity targeted by any Law which provides economic sanctions,
    export controls, import regulations or trade embargoes, nor You are
    otherwise owned or controlled by, or acting on behalf of, any person
    or entity targeted or in any way affected by the foregoing.
    Furthermore, You expressly acknowledge that You will not download or
    otherwise export or re-export the ${softwareName} Software’s content,
    products or services or any related technical data directly or
    indirectly to any person or entity targeted by the aforementioned Law
    or for any end-use prohibited or restricted by such Law.`.replace(
        /\s+/g,
        " "
      ),
      `Indemnification. To any third party’s claim arising out of a violation
    of Your obligations under this Section 4 (Your Responsibilities),
    Sects. 10.1 and 10.2 below shall apply, in addition to any other
    rights or remedies afforded to Us under this EULA and/or as per the
    applicable Law.`.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "LICENSE CHECKS, UPDATES, AVAILABILITY AND DISCONTINUATION",
    paragraphType: ["number", "number", "number", "number"],
    paragraph: [
      licenseChecks &&
        `License Checks. You expressly acknowledge and accept that the
        ${softwareName} Software need
        license checks before or during its execution. Therefore, You are
        strictly prohibited from alter or modify the
        ${softwareName} Software
        behaviour or functionality (e.g., prevent the software from
        connecting to the internet to perform such checks, etc.).`.replace(
          /\s+/g,
          " "
        ),
      `Updates. We, in Our sole discretion, reserve the right to add or
        remove tools, utilities, improvements, Third-Party Software, features
        or functions, or to provide programming fixes, updates and upgrades,
        to the ${softwareName} Software in
        order to improve and enhance the features and performance thereof
        and/or according to Our discontinuation policy.
        ${
          automaticUpdate &&
          `The 
        ${softwareName} Software contains
        automatic update technology, a feature used to provide maintenance
        (such as a bug fix, patches, enhanced functions, missing plug-ins, and
        new versions) and, by installing the same
        ${softwareName} Software initially,
        You consent to the transmission of standard computer information and
        the automatic downloading and installation of maintenance Software.`
        }
        Given the preceding, You expressly acknowledge and agree that: (i) We
        have no obligation to make available and/or provide any updates; (ii)
        in case We provide any updates, this EULA shall automatically apply
        thereto, unless We provide other terms along with such updates; (iii)
        We disclaim any liability in case any updates result in an
        unavailability, deficiency or incompatibility of any Third-Party
        Software (and/or of any other pre-existing feature or function) with
        the updated Software.`.replace(/\s+/g, " "),
      `Availability. We will use commercially reasonable efforts to ensure
        the availability of the ${softwareName} Software for Our
        customers. Regardless, You acknowledge and agree that We have no
        control or liability over the availability of the
        ${softwareName} Software on a
        continuous or uninterrupted basis, so that from time to time the same
        may be inaccessible or inoperable, including, without limitation, for:
        (i) equipment malfunctions, periodic maintenance procedures or
        repairs; (ii) Force Majeure; (iii) limitations due to Your particular
        device or hardware; (iv) unavailability, deficiency or incompatibility
        of any Third-Party Software with the
        ${softwareName} Software for any
        reason (e.g., third-party’s or Our discontinuation policy,
        incompatibilities between any updates and a Third-Party Software,
        etc.); (v) Your violation of any relevant provision of this EULA.
        [SubscriptionPlan_YES: Any unavailability of the
        ${softwareName}
        Software under this Sect. 5.3) shall not excuse You from Your
        obligation to make timely payment(s) under the applicable Subscription
        Plan.]`.replace(/\s+/g, " "),
      `Discontinuation policy. You expressly acknowledge and accept that the
        ${softwareName} Software is subject
        to Our discontinuation policy and, therefore, We reserve the right –
        without Our liability in such respect towards You or any other third
        party – to discontinue any
        ${!softwareName} Software’s feature,
        content or service (including, without limitation, access to technical
        support, access to Third-Party Software, etc.), in accordance with
        such discontinuation policy. The preceding applies also to any
        Third-Party Software, which is subject to such third party’s
        discontinuation policy, without Our liability towards You or any other
        third party in case any updates result in an unavailability,
        deficiency or incompatibility of any pre-existing Third-Party Software
        with the updated Software.`.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "TERM AND TERMINATION",
    paragraphType: ["number", "number", "number", "number"],
    paragraph: [
      `Term. The rights herein conferred to You are provided as of the
    Commencement Date unless terminated as provided below
    ${
      subscriptionPlan &&
      ` or according to the relevant Subscription Plan.
    In particular, the Subscription Plan shall be tacitly and
    automatically renewed for subsequent terms of the same duration,
    unless You withdraw by means of a ${subscriptionExpireNotice} calendar days’
    prior written notice, with effects after the expiration of the
    then-current subscription term, original or renewed`
    }
    .`.replace(/\s+/g, " "),
      `Termination for breach. In case of Your breach of any term herein
    provided not cured within 10 (ten) days after Our initial notice
    thereof, We are entitled to terminate the EULA
    ${subscriptionPlan && " and/or the relevant Subscription Plan"}.`.replace(
        /\s+/g,
        " "
      ),
      `Trial Period. The trial period is designed to enable You to evaluate
    – for free and for
    ${!trialDuration ? "[Trial_Period]" : trialDuration}
    calendar days as of the corresponding Commencement Date – all or
    part of the [Software_Name] Software and the related Services before
    taking out a paid Subscription Plan. Upon the expiration of the said
    trial period of
    ${!trialDuration ? "[Trial_Period]" : trialDuration}
    days without the purchase of a Subscription Plan, You will be
    prevented to access the evaluated
    ${softwareName} Software and the
    Services; to such purpose, You expressly acknowledge and accept that
    We are not be liable towards You, any Authorized User or any other
    third party for any Data and/or customization that might be lost due
    to the said limited access or fruition of the
    ${softwareName} Software and/or
    the related Services.`.replace(/\s+/g, " "),
      `No access after termination. Upon the termination, for any reason
    whatsoever, of this EULA, You no longer have rights to access or use
    the ${softwareName} Software and,
    if applicable, We will delete or otherwise make inaccessible Your
    account and any Data in the
    ${softwareName} Software, if any.
    We shall not be liable towards You or any other third party for the
    loss of any Data and/or customization after the termination of this
    EULA for whatever reason or cause.`.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "INTELLECTUAL PROPERTY RIGHTS",
    paragraphType: [""],
    paragraph: [
      `We hold and retain all an any title and interest in and to the
    ${softwareName} Software, as well as
    in any Intellectual Property Right associated with the same and,
    therefore, nothing in this EULA will be interpreted as a transfer, in
    whole or in part, of any IP Rights in and to the
    ${softwareName} Software to You or
    any other third party. Accordingly, You must not, and must not cause or
    permit others to: (i) remove or alter any
    ${softwareName} Software
    identification code, marking or notice from Us or Our licensors; (ii)
    copy, modify, adapt, make derivative works of, disassemble, decompile,
    reverse engineer, reproduce, distribute or republish any part of the
    ${softwareName} Software; (iii)
    perform or disclose any benchmark or performance tests of the
    ${softwareName} Software or any
    associated infrastructure (such as, without limitation, network
    discovery, port and service identification, vulnerability scanning,
    password cracking, remote access testing, penetration testing, etc.);
    and (iv) license, sell, rent, lease, transfer, assign, distribute, host,
    outsource, permit timesharing or service bureau use, or otherwise
    commercially exploit or make available the
    ${softwareName} Software to any third
    party. `.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "PRIVACY AND DATA PROTECTION",
    paragraphType: ["number", "number"],
    paragraph: [
      `Privacy Policy. You declare to have carefully read Our Privacy Policy
    (available at ${policyLink}) and
    got a clear understanding of how We collect, use, protect or otherwise
    handle Personal Data in the provision of the Services.`.replace(
        /\s+/g,
        " "
      ),
      `Anonymized Data analysis. We are allowed to analyze anonymized Data
    (i.e., Data that are no more Personal Data and that, therefore, do not
    include personal identifiers of a person, such as his or her name,
    e-mail address, address, etc.) for the purpose to maintain, update or
    improve the ${softwareName} Software and/or the Services, as well as to conduct internal
    statistics and analytics on the use and proper functioning of the same
    Services. You have no right, title or interest in any result of these
    analyses performed by Us, which shall be deemed as Our Intellectual
    Property Rights.`.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "WARRANTY AND LIABILITY",
    paragraphType: ["number", "number", "number", "number"],
    paragraph: [
      `Limited warranty. THE
    ${softwareName} SOFTWARE IS
    PROVIDED “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS”, WITHOUT
    WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY THE
    APPLICABLE LAW, WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR
    IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
    AND ANY WARRANTY THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
    PERFORMANCE, USAGE OR TRADE PRACTICE.`.replace(/\s+/g, " "),
      `Disclaimer of liability. IN NO EVENT SHALL WE BE LIABLE FOR ANY
    DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR
    CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
    SUBSTITUTE GOODS OR SERVICES, COMPUTER FAILURE OR MALFUNCTION, LOSS OF
    DATA OR PROFITS, BUSINESS INTERRUPTION, ETC.) HOWEVER CAUSED AND ON
    ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR
    TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE
    USE OF THE ${softwareName}
    SOFTWARE, EVEN IF WE ARE AWARE OF OR HAVE BEEN ADVISED OF THE
    POSSIBILITY OF SUCH DAMAGE.`.replace(/\s+/g, " "),
      monetaryCap &&
        `Remedies. EXCEPT FOR CLAIMS WITH RESPECT TO FRAUD, DEATH OR PERSONAL INJURY, FOR WHICH NO LIMIT SHALL APPLY, IN NO EVENT – TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW – OUR LIABILITY SHALL IN THE AGGREGATE EXCEED ${monetaryCap}.`,
      `Mandatory legal provisions. Some jurisdictions do not allow certain
    limitations of warranty or liability, therefore some or all of the
    limitations above provided may not apply to You. In such case, the
    limitations provided herein shall be applicable to the fullest and
    maximum extent permitted by the applicable Law.`.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "INDEMNIFICATION",
    paragraphType: ["number", "number"],
    paragraph: [
      `Indemnification. You shall indemnify and hold Us harmless from and
    against all damages, losses, and expenses of any kind (including
    reasonable legal fees and costs) arose by any claim made by any third
    party (including, without limitation, any agency, public
    administrations, entities, etc.) against Us in connection with any of
    Your: (i) violation or breach of any term of this EULA; (ii) violation
    of any applicable Law, whether or not referenced herein; (iii)
    violation of any rights of any third party; (iv) use or misuse of the
    ${softwareName} Software.`.replace(/\s+/g, " "),
      `Remedies. You expressly agree and acknowledge that any violation of
    Your obligations pursuant to this EULA may cause Us irreparable harm
    and damage, which may not be recovered at law. Therefore, You agree
    that Our remedies for breach of this EULA may be in equity by way of
    injunctive relief, as well and any other relief available, whether in
    law or in equity.`.replace(/\s+/g, " "),
    ],
  })

  FinalEula.push({
    title: "MISCELLANEOUS",
    paragraphType: ["number", "number", "number", "number", "number", "number"],
    paragraph: [
      `Assignment. You are not allowed to assign this EULA or any rights hereunder, unless with Our previous written consent. Conversely, We are allowed, at Our sole discretion, to assign this EULA or any rights hereunder to any third party, without giving You any prior notice.`,
      `Force majeure. A Party shall not be responsible for any failure to
perform due to any event of Force Majeure. In the event of any such
delay, the affected Party will be excused from such performance to the
extent it is delayed or prevented by such cause. However, the other
Party may terminate this EULA forthwith on written notice if such
Force Majeure condition continues for a period of sixty (60) days.`.replace(
        /\s+/g,
        " "
      ),
      `No implied waiver. Any tolerance or silence by Us, even if on a
continuous and reiterated basis, in relation to any breach or default
by You of any provision of this EULA shall not be considered as a
consent to such breaches and defaults and shall not affect the
validity of the clause breached nor shall be construed as a waiver to
any of the rights pertaining to Us pursuant to this EULA and/or the
applicable Law.`.replace(/\s+/g, " "),
      `Governing law and exclusive jurisdiction. This EULA is governed by the
substantive and procedural Laws of
${
  Object.values(applicableCountries).length > 0
    ? Object.values(applicableCountries).join(", ")
    : "[Jurisdiction_Place]"
}
. Any dispute arising out of, or relating to, this EULA shall be
submitted to the exclusive jurisdiction of the Court of
${
  Object.values(applicableCountries).length > 0
    ? Object.values(applicableCountries).join(", ")
    : "[Jurisdiction_Place]"
}
.`.replace(/\s+/g, " "),
      `Severability. Should any part of this EULA be declared illegal or
unenforceable, the remaining portion will remain in full force and
effects.`.replace(/\s+/g, " "),
      `Amendments. The effective date of the last version of this EULA is
written above. We have the right to amend this EULA at any time, and
to change, delete, discontinue or impose conditions on use of the
${softwareName} Software, in which
case the new terms and conditions will supersede prior terms. Any
changes will be effective after thirty (30) days from the time We
first notify You about such changes via email or/and notifications
while using the ${softwareName}
Software and/or other reasonable means. Your continued use of the
${softwareName} Software following
changes to this EULA after the effective date of a revised version
thereof constitutes Your expressed acceptance of, and agreement to be
bound by, this EULA as in force from time to time. If You do not
accept modified EULA in part or full, You must terminate Your account
and stop using the ${softwareName}
Software before the change takes effect.`.replace(/\s+/g, " "),
    ],
  })

  return FinalEula
}
