import React from "react"
import { OrderedList } from "./tab.styles"

const Rights = ({ EulaData }) => {
  const { CompanyForm, softwareForm } = EulaData
  const { commercialScope } = CompanyForm
  const { softwareName, severalUsers, subscriptionPlan, numberOfDevices } =
    softwareForm

  return (
    <div>
      <OrderedList>
        <li>
          Grant of license.{" "}
          {subscriptionPlan && "Subject to the relevant Subscription Plan,"} You
          are granted a limited, worldwide, non-assignable, non-sublicensable,
          non-transferable and non-exclusive license to use the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software according
          to the terms herein.
        </li>
        <li>
          Allowed devices. You are permitted to access and use the
          {!softwareName ? "[Software_Name]" : softwareName} Software by running
          only no. {!numberOfDevices ? "[Software_Devices]" : numberOfDevices}{" "}
          devices.{" "}
          {severalUsers &&
            "In case of Multi-User Account, the number of devices is allowed according to the relevant Subscription Plan."}
        </li>
        <li>
          Scope of the license.{" "}
          {commercialScope &&
            `You
          agree that You will use the
          ${!softwareName ? "[Software_Name]" : softwareName} Software only in
          connection with Your own normal internal commercial use, and You will
          not – unless otherwise provided in this EULA
          ${
            subscriptionPlan && "or by the applicable Subscription Plan"
          } – sell,
          lease, re-distribute, provide managed service, or otherwise provide or
          make available, either directly or via other means, or otherwise, the
          ${!softwareName ? "[Software_Name]" : softwareName} Software to any
          third party.`}
        </li>
        <li>
          Third-Party Software. You expressly acknowledge and accept that
          certain content, products and services available via the
          {!softwareName ? "[Software_Name]" : softwareName} Software may
          include Third-Party Software, as specified in the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software’s
          documentation. All Third-Party Software is licensed to You under the
          terms of this EULA, unless We provide other terms along with the{" "}
          {!softwareName ? "[Software_Name]" : softwareName}
          Software’s documentation. To any Third-Party Software shall apply
          Sects. 9.1), 9.2), 9.3) and 9.4) below and, therefore, without
          limiting the foregoing, We are not responsible for examining or
          evaluating the content or accuracy of the Third-Party Software nor
          provide any warranty, express or implied, with respect to any
          Third-Party Software and expressly disclaim any warranty or condition
          of merchantability, fitness for a particular purpose, title, and
          non-infringement.
        </li>
        <li>
          Reservation of rights. We expressly reserve all rights not expressly
          granted to You in this EULA.
        </li>
      </OrderedList>
    </div>
  )
}

export default Rights
