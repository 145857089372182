import styled from "styled-components";

export const StepHeaderWrapper = styled.div`
  padding-top: 16px;
  @media (max-width: 575px) {
    padding-top: 16px;
  }

  @media (max-width: 575px) {
    display: ${({ hide }) => hide && "none"};
  }
`;
export const StepHeaderFlex = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Indicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 20px;
  border: 2px solid #0b69a3;
  background-color: ${({ active }) => active && "#0B69A3"};
`;
