import styled from "styled-components"
import Grid from "../../images/Grid.png"

export const HeroWrapper = styled.div`
  background: var(
    --eula-hero-bg,
    linear-gradient(180deg, #2bb0ed 0%, rgba(255, 255, 255, 0) 100%)
  );
`
export const HeroHolder = styled.div`
  margin: 128px 0px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 56px;

  @media (max-width: 1024px) {
    gap: 32px;
  }

  @media (max-width: 815px) {
    margin: 64px 0px;
    flex-direction: column;
    align-items: flex-start;
  }
`
export const TitleHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 64px;

  @media (max-width: 815px) {
    margin-bottom: 32px;
  }
`
export const HeroMain = styled.h1`
  color: #035388;
  font-size: 64px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 64px;
  max-width: 545px;

  @media (max-width: 1105px) {
    font-size: 48px;
  }

  @media (max-width: 815px) {
    font-size: 36px;
    line-height: 40px;
  }
`
export const HeroSubTitle = styled.h2`
  color: #3b3b3b;
  font-size: 32px;
  font-family: Karla;
  font-weight: 700;
  line-height: normal;
`
export const HeroImage = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 625px;
  width: 532.5px;
  border-radius: 0px 0px 0px 32px;
  background-image: url(${Grid});
  background-color: #0b69a3;
  background-size: 60%;
  background-position: center;

  box-shadow: 0px 8px 8px 0px rgba(151, 151, 151, 0.25);

  @media (max-width: 1105px) {
    // height: 600px;
  }

  @media (max-width: 990px) {
    // height: 700px;
  }

  @media (max-width: 815px) {
    height: auto;
    width: 100%;
  }

  & > img {
    width: 90%;
    height: auto;
  }
`
