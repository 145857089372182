import React, { useEffect } from "react"
import {
  BottomComponent,
  Edit,
  EditButton,
  PreviewButton,
  StepOneWrapper,
  TopComponent,
} from "./step1.styled"
import CompanyQuestions from "./company-questions/company-questions"
import { useState } from "react"
import { Button } from "../../shared/button"
import SoftwareQuestions from "./software-questions/software-questions"
import PrivacyQuestions from "./privacy-questions/privacy-questions"
import PreviewSection from "../PreviewSection/preview-section"
import PreviewSectionMobile from "../PreviewSectionMobile/preview-section-mobile"
import StepHeader from "./step-header/step-header"
import Step2Mobile from "../step 2-mobile/Step2Mobile"

const Step1 = ({
  setStepsCompleted,
  stepsCompleted,
  EulaData,
  companyForm,
  setCompanyForm,
  softwareForm,
  setSoftwareForm,
  privacyForm,
  setPrivacyForm,
}) => {
  const [QuestionSection, setQuestionSection] = useState(0)

  const [activeTab, setActiveTab] = useState(0)
  const [previewTabOpen, setPreviewTabOpen] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  //Company Form States}
  const {
    companyName,
    registeredAdress,
    businessRegistrationNumber,
    taxCode,
    taxCodeToggled,
    policyLink,
    isCompany,
  } = companyForm
  //Software Form States
  const {
    softwareName,
    softwareDescription,
    severalUsers,
    numberOfDevices,
    subscriptionPlan,
    subscriptionExpireNotice,
    softwareLink,
    trialPeriod,
    trialDuration,
  } = softwareForm
  //Privacy Form States
  const {
    effectiveDate,
    relevantCourt,
    fullRegisteredAddress,
    monetaryCap,
    monetaryCapSelected,
    dataProcessingLink,
    dataProcessing,
    applicableCountries,
  } = privacyForm

  const [companyFormValidity, setCompanyFormValidity] = useState({
    isCompanyValid: true,
    companyNameValid: true,
    registeredAdressValid: true,
    businessRegistrationNumberValid: true,
    taxCodeValid: true,
    policyLinkValid: true,
  })

  const [softwareFormValidity, setSoftwareFormValidity] = useState({
    softwareNameValid: true,
    softwareLinkValid: true,
    softwareDescriptionValid: true,
    numberOfDevicesValid: true,
    subscriptionExpireNoticeValid: true,
    trialDateValid: true,
  })
  const [privacyFormValidity, setPrivacyFormValidity] = useState({
    effectiveDateValid: true,
    relevantCourtValid: true,
    fullRegisteredAddressValid: true,
    monetaryCapValid: true,
    dataProcessingLinkValid: true,
    countrieSelectedValid: true,
  })

  const ReturnHandler = () => {
    if (QuestionSection === 0 && !previewTabOpen) {
      setStepsCompleted(0)
    } else if (QuestionSection !== 0 && !previewTabOpen) {
      setQuestionSection(QuestionSection - 1)
      if (QuestionSection === 2) {
        setStepsCompleted(1)
      }
    }
  }

  const SwitchTabFoward = () => {
    if (!previewTabOpen) {
      setQuestionSection(QuestionSection + 1)
    }
  }

  useEffect(() => {
    setButtonDisabled(false)
  }, [QuestionSection])

  const CompanyFormSubmit = () => {
    let validity = { ...companyFormValidity }
    let isFormValid = true

    if (companyName === "") {
      validity.companyNameValid = false
      setButtonDisabled(true)
      isFormValid = false
    } else {
      validity.companyNameValid = true
    }
    if (registeredAdress === "") {
      validity.registeredAdressValid = false
      setButtonDisabled(true)
      isFormValid = false
    } else {
      validity.registeredAdressValid = true
    }
    if (isCompany && businessRegistrationNumber === "") {
      validity.businessRegistrationNumberValid = false
      setButtonDisabled(true)
      isFormValid = false
    } else {
      validity.businessRegistrationNumberValid = true
    }
    if (policyLink === "") {
      validity.policyLinkValid = false
      setButtonDisabled(true)
      isFormValid = false
    } else {
      let urlRegex =
        /^(https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9()]{1,6}(?:\/[^\s]*)?$/
      if (urlRegex.test(policyLink)) {
        validity.policyLinkValid = true
      } else {
        validity.policyLinkValid = false
        setButtonDisabled(true)
        isFormValid = false
      }
    }

    if (taxCodeToggled && taxCode === "") {
      validity.taxCodeValid = false
      isFormValid = false
    } else {
      validity.taxCodeValid = true
    }

    if (isFormValid) {
      setButtonDisabled(false)
      SwitchTabFoward()
    }
    setCompanyFormValidity(validity)
  }

  useEffect(() => {
    const areRequiredFieldsFilled =
      companyName !== "" && registeredAdress !== "" && policyLink !== ""

    const isBusinessRegistrationValid =
      !isCompany || (isCompany && businessRegistrationNumber !== "")

    const isTaxCodeValid = !taxCodeToggled || (taxCodeToggled && taxCode !== "")

    if (
      areRequiredFieldsFilled &&
      isBusinessRegistrationValid &&
      isTaxCodeValid
    ) {
      setButtonDisabled(false)
    }
  }, [
    companyName,
    registeredAdress,
    businessRegistrationNumber,
    taxCode,
    policyLink,
    isCompany,
    taxCodeToggled,
    taxCode,
  ])

  const SoftwareFormSubmit = () => {
    let validity = { ...softwareFormValidity }

    if (softwareName === "") {
      setButtonDisabled(true)
      validity.softwareNameValid = false
    } else {
      validity.softwareNameValid = true
    }

    const isSoftwareLinkValid =
      /^(https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9()]{1,6}(?:\/[^\s]*)?$/.test(
        softwareLink
      )
    if (softwareLink === "" || !isSoftwareLinkValid) {
      validity.softwareLinkValid = false
      setButtonDisabled(true)
    } else {
      validity.softwareLinkValid = true
    }

    if (softwareDescription === "") {
      setButtonDisabled(true)
      validity.softwareDescriptionValid = false
    } else {
      validity.softwareDescriptionValid = true
    }

    if (subscriptionPlan) {
      if (subscriptionExpireNotice === "") {
        setButtonDisabled(true)
        validity.subscriptionExpireNoticeValid = false
      } else {
        validity.subscriptionExpireNoticeValid = true
      }
    }

    if (numberOfDevices === "" && severalUsers) {
      setButtonDisabled(true)
      validity.numberOfDevicesValid = false
    } else {
      validity.numberOfDevicesValid = true
    }

    if (trialPeriod) {
      setButtonDisabled(true)
      validity.trialDateValid = false
    }

    const allFieldsFilled =
      softwareName !== "" &&
      softwareLink !== "" &&
      isSoftwareLinkValid &&
      softwareDescription !== "" &&
      (!severalUsers || (severalUsers && numberOfDevices !== "")) &&
      (!subscriptionPlan ||
        (subscriptionPlan && subscriptionExpireNotice !== "")) &&
      (!trialPeriod || (trialPeriod && trialDuration !== ""))

    if (allFieldsFilled) {
      setButtonDisabled(false)
      SwitchTabFoward()
    } else if (
      trialPeriod &&
      trialDuration != "" &&
      softwareName !== "" &&
      softwareLink !== "" &&
      isSoftwareLinkValid &&
      softwareDescription !== "" &&
      (!severalUsers || (severalUsers && numberOfDevices !== "")) &&
      (!subscriptionPlan ||
        (subscriptionPlan && subscriptionExpireNotice !== ""))
    ) {
      setButtonDisabled(false)
      SwitchTabFoward()
    } else {
      setButtonDisabled(true)
    }

    setSoftwareFormValidity(validity)
  }

  useEffect(() => {
    const areRequiredFieldsFilled =
      softwareName !== "" && softwareLink !== "" && softwareDescription !== ""

    const isNumberOfUsersFilled =
      !severalUsers || (severalUsers && numberOfDevices !== "")
    const WithdrawalPeridoFilled =
      !subscriptionPlan || (subscriptionPlan && subscriptionExpireNotice !== "")
    if (
      areRequiredFieldsFilled &&
      isNumberOfUsersFilled &&
      WithdrawalPeridoFilled
    ) {
      setButtonDisabled(false)
    }
  }, [
    softwareName,
    softwareLink,
    softwareDescription,
    severalUsers,
    numberOfDevices,
    subscriptionPlan,
    subscriptionExpireNotice,

    trialPeriod,
    trialDuration,
  ])
  useEffect(() => {
    if (trialPeriod === true && trialDuration !== "") {
      setButtonDisabled(false)
    }
  }, [trialPeriod, trialDuration])

  const isDataLinkValid = (dataLink) =>
    /^(https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9()]{1,6}(?:\/[^\s]*)?$/.test(
      dataLink
    )

  const PrivacyFormSubmit = () => {
    let validity = { ...privacyFormValidity }

    if (effectiveDate === "") {
      validity.effectiveDateValid = false
      setButtonDisabled(true)
    } else {
      validity.effectiveDateValid = true
    }

    if (relevantCourt === "") {
      validity.relevantCourtValid = false
      setButtonDisabled(true)
    } else {
      validity.relevantCourtValid = true
    }

    if (fullRegisteredAddress === "") {
      validity.fullRegisteredAddressValid = false
      setButtonDisabled(true)
    } else {
      validity.fullRegisteredAddressValid = true
    }
    if (Object.keys(applicableCountries).length === 0) {
      validity.countrieSelectedValid = false
      setButtonDisabled(true)
    } else {
      validity.countrieSelectedValid = true
    }

    if (monetaryCapSelected) {
      if (monetaryCap === "") {
        validity.monetaryCapValid = false
        setButtonDisabled(true)
      } else {
        validity.monetaryCapValid = true
      }
    } else {
      validity.monetaryCapValid = true
    }

    if (dataProcessing) {
      const isDataProcessingLinkValid = isDataLinkValid(dataProcessingLink)

      if (dataProcessingLink === "" || !isDataProcessingLinkValid) {
        validity.dataProcessingLinkValid = false
        setButtonDisabled(true)
      } else {
        validity.dataProcessingLinkValid = true
        setButtonDisabled(false)
      }
    } else {
      validity.dataProcessingLinkValid = true
      setButtonDisabled(false)
    }

    const allFieldsFilled =
      effectiveDate !== "" &&
      relevantCourt !== "" &&
      Object.keys(applicableCountries).length !== 0 &&
      (!monetaryCapSelected || (monetaryCapSelected && monetaryCap !== "")) &&
      (!dataProcessing ||
        (dataProcessing && isDataLinkValid(dataProcessingLink)))

    if (allFieldsFilled) {
      setButtonDisabled(false)
      setStepsCompleted(2)
      console.log(EulaData)
    }

    setPrivacyFormValidity(validity)
  }

  useEffect(() => {
    const areRequiredFieldsFilled =
      effectiveDate !== "" &&
      relevantCourt !== "" &&
      Object.keys(applicableCountries).length !== 0

    const monetaryCapSelectedAndValid =
      !monetaryCapSelected || (monetaryCapSelected && monetaryCap !== "")

    const dataLinkCheck =
      !dataProcessing || (dataProcessing && isDataLinkValid(dataProcessingLink))

    if (
      areRequiredFieldsFilled &&
      monetaryCapSelectedAndValid &&
      dataLinkCheck
    ) {
      setButtonDisabled(false)
    }
  }, [
    effectiveDate,
    relevantCourt,
    dataProcessingLink,
    applicableCountries,
    monetaryCap,
    monetaryCapSelected,
    dataProcessing,
  ])

  const ResetForms = () => {
    if (QuestionSection === 0) {
      setCompanyForm({
        isCompany: false,
        companyName: "",
        registeredAdress: "",
        businessRegistrationNumber: "",
        commercialScope: false,
        taxCode: "",
        policyLink: "",
      })
      setSoftwareForm({
        softwareName: "",
        softwareLink: "",
        softwareDescription: "",
        licenseChecks: false,
        accountRegistration: false,
        severalUsers: false,
        numberOfDevices: "",
        automaticUpdate: false,
        trialPeriod: false,
        trialDuration: "",
        subscriptionPlan: false,
        subscriptionExpireNotice: "",
      })
      setPrivacyForm({
        effectiveDate: "",
        applicableCountries: {},
        relevantCourt: "",
        fullRegisteredAddress: "",
        monetaryCap: "",
        dataProcessingLink: "",
        dataProcessing: false,
      })
    }
  }
  return (
    <StepOneWrapper mobile={previewTabOpen}>
      <TopComponent>
        <EditButton
          onClick={() => {
            setPreviewTabOpen(false)
            setActiveTab(0)
          }}
          active={activeTab === 0}
        >
          <Edit active={activeTab === 0}>EDIT</Edit>
        </EditButton>
        <PreviewButton
          onClick={() => {
            setPreviewTabOpen(true)
            setActiveTab(1)
          }}
          active={activeTab === 1}
        >
          <Edit active={activeTab === 1}>PREVIEW</Edit>
        </PreviewButton>
      </TopComponent>
      {!previewTabOpen ? (
        <>
          <StepHeader
            stepsCompleted={stepsCompleted}
            QuestionSection={QuestionSection}
            setQuestionSection={setQuestionSection}
          />
          {stepsCompleted >= 1 && (
            <>
              {QuestionSection === 0 && (
                <CompanyQuestions
                  stepsCompleted={stepsCompleted}
                  companyForm={companyForm}
                  setCompanyForm={setCompanyForm}
                  companyFormValidity={companyFormValidity}
                  setCompanyFormValidity={setCompanyFormValidity}
                />
              )}
              {QuestionSection === 1 && (
                <SoftwareQuestions
                  stepsCompleted={stepsCompleted}
                  softwareForm={softwareForm}
                  setSoftwareForm={setSoftwareForm}
                  softwareFormValidity={softwareFormValidity}
                  setSoftwareFormValidity={setSoftwareFormValidity}
                />
              )}
              {QuestionSection === 2 && (
                <PrivacyQuestions
                  stepsCompleted={stepsCompleted}
                  privacyForm={privacyForm}
                  setPrivacyForm={setPrivacyForm}
                  privacyFormValidity={privacyFormValidity}
                  setPrivacyFormValidity={setPrivacyFormValidity}
                />
              )}
            </>
          )}
          {stepsCompleted === 2 && <Step2Mobile />}
        </>
      ) : (
        <PreviewSectionMobile EulaData={EulaData} />
      )}

      <BottomComponent>
        <Button
          type="dark"
          shadow
          width={100}
          maxw={150}
          onClick={() => {
            ReturnHandler()
            ResetForms()
          }}
        >
          BACK
        </Button>
        {QuestionSection === 2 ? (
          <Button
            disabled={buttonDisabled}
            type="yellow"
            color="#8D2B0B"
            shadowSecondary
            width={100}
            maxw={150}
            newmaxw={110}
            onClick={() => {
              if (QuestionSection === 2) {
                PrivacyFormSubmit()
              }
            }}
          >
            Generate
          </Button>
        ) : (
          <Button
            disabled={buttonDisabled}
            type="dark"
            shadow
            width={100}
            maxw={150}
            onClick={() => {
              if (QuestionSection === 0) {
                CompanyFormSubmit()
              }
              if (QuestionSection === 1) {
                SoftwareFormSubmit()
              }
            }}
          >
            NEXT
          </Button>
        )}
      </BottomComponent>
    </StepOneWrapper>
  )
}

export default Step1
