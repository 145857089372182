import React from "react"
import {
  PreviewContetText,
  PreviewContetTitle,
} from "../preview-section.styled"

const EndUserAgreement = ({ EulaData }) => {
  const { CompanyForm, softwareForm, privacyForm } = EulaData
  const { softwareName } = softwareForm
  const { effectiveDate } = privacyForm
  const {
    isCompany,
    companyName,
    registeredAdress,
    businessRegistrationNumber,
    taxCode,
    taxCodeToggled,
  } = CompanyForm

  return (
    <div>
      <PreviewContetTitle margin="0px 0px 8px 0px">
        Effective Date:{" "}
        {!effectiveDate ? "[EULA_Effective_Date]" : effectiveDate}
      </PreviewContetTitle>
      <PreviewContetText margin="0px 0px 20px 0px">
        This end user license agreement (hereinafter referred to as the “EULA”)
        constitute a legal and binding agreement between{" "}
        {!companyName ? "[Licensor_Name]" : companyName},{" "}
        {!isCompany
          ? `with
        place of residence at ${
          !registeredAdress ? "[Licensor_Address]" : registeredAdress
        }
        ${!taxCodeToggled ? "" : `, Tax code no. ${taxCode}`},`
          : `with registered office at
            ${
              !registeredAdress ? "[Licensor_Address]" : registeredAdress
            }, business registration no. ${
              !businessRegistrationNumber
                ? "[Licensor_BusinessNumber]"
                : businessRegistrationNumber
            } ,
          including its subsidiaries, affiliates and contractors acting on its
          behalf`}{" "}
        (hereinafter collectively referred to as “Us”, “We”, or “Our”) and any
        person or entity and its heirs, agents, successors and assigns that have
        purchased, or is authorized to, access Our{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software (hereinafter
        singularly and collectively referred to as “You” or “Your”).
      </PreviewContetText>
      <PreviewContetText weight={600}>
        IMPORTANT NOTICE: Your continued use of the{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software constitutes
        Your expressed acceptance of, and agreement to be bound by, this EULA,
        as in force from time to time. Therefore, You understand and acknowledge
        that by downloading, accessing, installing or using the{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software You agree to
        be bound by the terms of this EULA. If You do not accept such terms in
        their entirety, You must not download, access, install or use the{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software. If You
        agree to this EULA on behalf of an entity, or in connection with
        providing or receiving services on behalf of an entity, You represent
        and warrant that You have the authority to bind that entity to this
        EULA. In such an event, “You” and “Your” will refer and apply to that
        entity or agency.
      </PreviewContetText>
    </div>
  )
}

export default EndUserAgreement
