import React from "react"
import {
  HeroHolder,
  HeroImage,
  HeroMain,
  HeroSubTitle,
  HeroWrapper,
  TitleHolder,
} from "./hero.styled"
import { Container, Paragraph } from "../../shared/shared.styles"
import { Button } from "../../shared/button"
import HeroImg from "../../images/CTA.png"

const Hero = ({ setGenerateOn }) => {
  return (
    <HeroWrapper>
      <Container>
        <HeroHolder>
          <HeroImage>
            <img
              src={HeroImg}
              alt="Handshake symbolizing the completion of a EULA."
            />
          </HeroImage>
          <section>
            <TitleHolder>
              <HeroSubTitle>Free EULA Generator For Your Software</HeroSubTitle>
              <HeroMain>
                EULA Templates for your web, mobile and desktop apps
              </HeroMain>
              <Paragraph maxw={544.511}>
                Every app requires a EULA to permit software download and use,
                protect copyrights, and impose usage restrictions.
                LicenseSpring's generator swiftly creates a custom EULA in
                minutes—no account or credit card required. It's easy, and your
                EULA will be ready to use immediately.
              </Paragraph>
            </TitleHolder>
            <Button
              color="#F7F7F7"
              shadow
              type="light"
              mobile
              onClick={() => {
                setGenerateOn(true)
              }}
            >
              Generate Now
            </Button>
          </section>
        </HeroHolder>
      </Container>
    </HeroWrapper>
  )
}

export default Hero
