import React, { useEffect } from "react"
import { OrderedList } from "./tab.styles"

const TermTermination = ({ EulaData }) => {
  const { softwareForm } = EulaData

  const {
    softwareName,
    trialPeriod,
    trialDuration,
    subscriptionPlan,
    subscriptionExpireNotice,
  } = softwareForm

  useEffect(() => {
    console.log("From form: ", trialDuration)
  }, [trialDuration])

  return (
    <div>
      <OrderedList>
        <li>
          {" "}
          Term. The rights herein conferred to You are provided as of the
          Commencement Date unless terminated as provided below
          {subscriptionPlan &&
            ` or according to the relevant Subscription Plan.
          In particular, the Subscription Plan shall be tacitly and
          automatically renewed for subsequent terms of the same duration,
          unless You withdraw by means of a ${
            !subscriptionExpireNotice
              ? "[Subscription_Notice]"
              : subscriptionExpireNotice
          } calendar days’
          prior written notice, with effects after the expiration of the
          then-current subscription term, original or renewed`}
          .
        </li>
        <li>
          Termination for breach. In case of Your breach of any term herein
          provided not cured within 10 (ten) days after Our initial notice
          thereof, We are entitled to terminate the EULA{" "}
          {subscriptionPlan && " and/or the relevant Subscription Plan"}.
        </li>
        {trialPeriod && (
          <li>
            Trial Period. The trial period is designed to enable You to evaluate
            – for free and for{" "}
            {!trialDuration ? "[Trial_Period]" : trialDuration} calendar days as
            of the corresponding Commencement Date – all or part of the
            [Software_Name] Software and the related Services before taking out
            a paid Subscription Plan. Upon the expiration of the said trial
            period of {!trialDuration ? "[Trial_Period]" : trialDuration} days
            without the purchase of a Subscription Plan, You will be prevented
            to access the evaluated{" "}
            {!softwareName ? "[Software_Name]" : softwareName} Software and the
            Services; to such purpose, You expressly acknowledge and accept that
            We are not be liable towards You, any Authorized User or any other
            third party for any Data and/or customization that might be lost due
            to the said limited access or fruition of the{" "}
            {!softwareName ? "[Software_Name]" : softwareName} Software and/or
            the related Services.
          </li>
        )}
        <li>
          No access after termination. Upon the termination, for any reason
          whatsoever, of this EULA, You no longer have rights to access or use
          the {!softwareName ? "[Software_Name]" : softwareName} Software and,
          if applicable, We will delete or otherwise make inaccessible Your
          account and any Data in the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software, if any.
          We shall not be liable towards You or any other third party for the
          loss of any Data and/or customization after the termination of this
          EULA for whatever reason or cause.
        </li>
      </OrderedList>
    </div>
  )
}

export default TermTermination
