import React from "react"
import {
  Container,
  MainTitle,
  Paragraph,
  SubTitle,
} from "../../shared/shared.styles"
import {
  AboutEulaHolder,
  AboutEulaWrapper,
  FLexBox,
  ParagraphHolder,
  TextHolder,
} from "./about-eula.styled"
import Image from "../../images/person-laptop.png"

const AboutEula = () => {
  return (
    <AboutEulaWrapper>
      <Container noBorder>
        <AboutEulaHolder>
          <MainTitle mobileFs white center fs={48}>
            What is a EULA
            <br /> and who needs one?
          </MainTitle>
          <FLexBox>
            <TextHolder>
              <ParagraphHolder>
                <Paragraph white>
                  An End-User License Agreement (EULA) is a legal agreement
                  granting a user a license to use an application or software.
                  It must be consented to before a user buys, installs, or
                  downloads an application or software owned by the service
                  provider.
                </Paragraph>
              </ParagraphHolder>
              <ParagraphHolder>
                <Paragraph white>
                  As a software owner, having a EULA is vital to protect your
                  ownership rights and notify users of their obligations. If an
                  issue arises, a EULA sets out the guidelines of ownership
                  rights and licensee rights; it also includes the dos and
                  don’ts of the licensee. 
                </Paragraph>
              </ParagraphHolder>
            </TextHolder>
            <img
              src={Image}
              alt="Worker giving a thumbs up while on a laptop."
            />
          </FLexBox>
        </AboutEulaHolder>
      </Container>
    </AboutEulaWrapper>
  )
}

export default AboutEula
