import React from "react"
import { PreviewTitles } from "../../../shared/shared.styles"
import {
  Indicator,
  StepHeaderFlex,
  StepHeaderWrapper,
} from "./step-header-styled"

const StepHeader = ({ QuestionSection, stepsCompleted }) => {
  return (
    <StepHeaderWrapper hide={stepsCompleted === 2}>
      {QuestionSection === 0 && (
        <PreviewTitles center>1. COMPANY-RELATED QUESTIONS</PreviewTitles>
      )}
      {QuestionSection === 1 && (
        <PreviewTitles center>2. SOFTWARE-RELATED QUESTIONS</PreviewTitles>
      )}
      {QuestionSection === 2 && (
        <PreviewTitles center>
          3. PRIVACY & DATA-RELATED QUESTIONS
        </PreviewTitles>
      )}

      <StepHeaderFlex>
        <Indicator active={QuestionSection === 0} />
        <Indicator active={QuestionSection === 1} />
        <Indicator active={QuestionSection === 2} />
      </StepHeaderFlex>
    </StepHeaderWrapper>
  )
}

export default StepHeader
