import React from "react"
import { PreviewContetText } from "../preview-section.styled"

const IntellectualProperty = ({ EulaData }) => {
  const { softwareForm } = EulaData
  const { softwareName } = softwareForm

  return (
    <div>
      <PreviewContetText>
        We hold and retain all an any title and interest in and to the
        {!softwareName ? "[Software_Name]" : softwareName} Software, as well as
        in any Intellectual Property Right associated with the same and,
        therefore, nothing in this EULA will be interpreted as a transfer, in
        whole or in part, of any IP Rights in and to the{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software to You or
        any other third party. Accordingly, You must not, and must not cause or
        permit others to: (i) remove or alter any{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software
        identification code, marking or notice from Us or Our licensors; (ii)
        copy, modify, adapt, make derivative works of, disassemble, decompile,
        reverse engineer, reproduce, distribute or republish any part of the{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software; (iii)
        perform or disclose any benchmark or performance tests of the{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software or any
        associated infrastructure (such as, without limitation, network
        discovery, port and service identification, vulnerability scanning,
        password cracking, remote access testing, penetration testing, etc.);
        and (iv) license, sell, rent, lease, transfer, assign, distribute, host,
        outsource, permit timesharing or service bureau use, or otherwise
        commercially exploit or make available the{" "}
        {!softwareName ? "[Software_Name]" : softwareName} Software to any third
        party. 
      </PreviewContetText>
    </div>
  )
}

export default IntellectualProperty
