import styled from "styled-components"

export const EulaInput = styled.input`
  min-height: 42px;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  color: #7e7e7e;
  font-family: Karla;
  font-size: 16px;
  font-weight: ${(weight) => (weight ? weight : "700")};
  line-height: 26px;

  color: #0b69a3;

  &:focus {
    color: #0b69a3;
    font-weight: 700;
    border-radius: 8px;
    border: 1px solid var(--blue-60-master, #2bb0ed);
    background: #fff;
  }

  &:focus::placeholder {
    font-weight: 400;
  }
`
export const CheckBoxHolder = styled.div`
  display: flex;

  gap: 10px;
`
export const CheckBox = styled.input`
  appearance: none;
  width: 21px;
  height: 21px;

  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  box-shadow: 0px 4px 8px 0px rgba(50, 50, 71, 0.06),
    0px 4px 4px 0px rgba(50, 50, 71, 0.08);
  cursor: pointer;

  &:checked {
    background-color: #0b69a3;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 7px;
    width: 5px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

export const EulaTextArea = styled.textarea`
  min-height: 42px;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  color: #7e7e7e;
  font-family: Karla;
  font-size: 16px;
  font-weight: ${(weight) => (weight ? weight : "700")};
  line-height: 26px;

  color: #0b69a3;

  &:focus {
    color: #0b69a3;
    font-weight: 700;
    border-radius: 8px;
    border: 1px solid var(--blue-60-master, #2bb0ed);
    background: #fff;
  }

  &:focus::placeholder {
    font-weight: 400;
  }
`
