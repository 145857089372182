import React, { useState } from "react"
import Layout from "../components/Layout/NewLayout/NewLayout"
import Hero from "../components/eula/landing/hero-section/hero"
import AboutEula from "../components/eula/landing/eula-about/about-eula"
import EulaUse from "../components/eula/landing/eula-use/use-eula"
import EulaFaq from "../components/eula/landing/eula-faq/eula-faq"
import EulaTemplate from "../components/eula/landing/eula-template/eula-template"
import Progressbar from "../components/eula/EulaGenerator/progressbar/progressbar"
import EulaGenerator from "../components/eula/EulaGenerator/eula-generator/eula-generator"
import Seo from "../components/Seo"

const schemaMarkup = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://licensespring.com/eula-generator/"
  },
  "headline": "Free EULA Generator: EULA Templates for your web, mobile and desktop apps",
  "description": "eliable, cross-platform, platform for licensing your software. Used in large deployments and standalone applications alike. Open your account today!",
  "image": [
    "https://licensespring.com/static/CTA-c1775cacf43125eefb5625b17ba82dd5.png",
    "https://licensespring.com/static/person-laptop-9e6b0a4aa51601ca0aa320e839e581bc.png",
    "https://licensespring.com/static/Q1-060645785b58fa43bd4e44c018f98e96.png"
  ],  
  "author": {
  "@context": "https://schema.org/",
  "@type": "Person",
  "name": "Edmon Moren",
  "url": "https://licensespring.com/blog/Edmon-Moren/",
  "image": "https://images.prismic.io/licensespring/8ff52074-a9cf-4a48-bdee-7e6414ec914d_me.jpeg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&w=322&h=322",
  "sameAs": [
    "https://www.linkedin.com/in/edmonmoren/",
    "https://twitter.com/LicenseSpring",
    "https://www.youtube.com/@licensespring"
  ],
  "jobTitle": "Co-Founder",
  "worksFor": {
    "@type": "Organization",
    "name": "LicenseSpring Software"
  }  
}},  
  "datePublished": "2023-08-11",
  "dateModified": "2023-08-11"
}
</script>
`

const Eula = () => {
  const [generateOn, setGenerateOn] = useState(false)
  const [stepsCompleted, setStepsCompleted] = useState(0)

  const title = "Free EULA Generator | EULA Online Template"

  if (!generateOn) {
    return (
      <Layout>
        <Seo
          title={title}
          description="Easily generate custom EULAs with LicenseSpring's EULA Generator. Comply with legal requirements and protect your software. Try now!"
          schemaMarkup={schemaMarkup}
        />
        <Hero setGenerateOn={setGenerateOn} />
        <EulaUse setGenerateOn={setGenerateOn} />
        <AboutEula />
        <EulaFaq />
        <EulaTemplate setGenerateOn={setGenerateOn} />
      </Layout>
    )
  }
  if (generateOn) {
    return stepsCompleted == 2 ? (
      <Layout>
        {/* <Progressbar stepsCompleted={stepsCompleted} />  for now it was decided that it shoud be removed */}
        <Seo
          title={title}
          description="Easily generate custom EULAs with LicenseSpring's EULA Generator. Comply with legal requirements and protect your software. Try now!"
        />
        <EulaGenerator
          stepsCompleted={stepsCompleted}
          setStepsCompleted={setStepsCompleted}
        />
      </Layout>
    ) : (
      <Layout>
        <Seo
          title={title}
          description="Easily generate custom EULAs with LicenseSpring's EULA Generator. Comply with legal requirements and protect your software. Try now!"
        />
        <EulaGenerator
          stepsCompleted={stepsCompleted}
          setStepsCompleted={setStepsCompleted}
        />
      </Layout>
    )
  }
}

export default Eula
