import styled, { keyframes } from "styled-components"

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const LoaderSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #0b69a3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
`

export const StepTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
  border-radius: 8px;
  width: 100%;
  margin-left: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 848px;

  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }
`
export const StepTwoTitle = styled.h1`
  color: #0b69a3;
  font-family: Karla;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.5px;
  max-width: 468px;

  @media (max-width: 765px) {
    font-size: 32px;
    line-height: 52px;
  }
`

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 16px;
  padding-top: 16px;
  border: 2px solid #f7f7f7;
  border-radius: 8px;
  background: #f7f7f7;
`

export const CloseHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

export const IconHolder = styled.div`
  padding: 8px;
  cursor: pointer;
`
export const Icon = styled.img`
  max-width: 70px;
  max-height: 90px;
`
export const ItemHolder = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  gap: 32px;

  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  cursor: pointer;
`
export const Disclosure = styled.p`
  max-width: 600px;
  color: #7e7e7e;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 885px) {
    font-size: 12px;
  }
`

export const BottomSection = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 29px;
  align-self: stretch;

  border-radius: 8px;
  background: #0b69a3;
`
export const BottomSectioTitle = styled.div`
  color: #f7f7f7;
  font-family: Karla;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
`
export const HeartIcon = styled.span`
  color: red;
`
export const BottomSectionText = styled.p`
  max-width: 400px;
  color: #f7f7f7;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-right: auto;
`

export const BottomFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  @media (max-width: 885px) {
    flex-direction: column;
    gap: 29px;
  }
`

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  width: 100%;
`

export const IconFlex = styled.div`
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 8px;
`
export const LinkHolder = styled.div`
  margin: 0 auto;
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  width: 100%;

  color: #0b69a3;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 675px) {
    font-size: 14px;
  }
`
