import axios from "axios"

export function SendEmail(
  eula,
  setLoading,
  setEmailSent,
  setModalOpen,
  email,
  setEmail
) {
  axios
    .post(
      "https://ulr2nwvnwww2pmtwe7tjdcymw40foyjw.lambda-url.us-east-2.on.aws/",
      { eula, email }
    )
    .then((res) => {
      setLoading(false)
      setEmailSent(true)
      setModalOpen(false)
      console.log("Email sent!")
    })
    .catch((err) => {
      setLoading(false)
      setEmail("")
      setEmailSent("Wrong email address")
      console.error(err)
    })
}

export function GetDocx(eula, setLoading) {
  axios
    .post(
      "https://ynbhfvuxkjzv3w5h36p7vqdbb40ftrwv.lambda-url.us-east-2.on.aws/",
      { eula },
      { responseType: "blob" } // To handle binary data
    )
    .then((res) => {
      console.log("Generated docx")
      const link = document.createElement("a")
      link.href = URL.createObjectURL(new Blob([res.data]))
      link.download = "EULA-Policy.docx"
      document.body.appendChild(link)
      link.click()
      setLoading(false)
    })
    .catch((err) => console.error(err))
}

export function GetTxt(eulaData, setLoading) {
  let output = "EULA Policy\n\n"

  eulaData?.forEach((element, eIndex) => {
    output += `${element.title}\n`
    let number = 1 // Initialize a counter for the list
    element.paragraph?.forEach((paragraph, i) => {
      if (!paragraph) {
        return
      } else {
        if (element.paragraphType[i] === "number") {
          output += `${number}. ${paragraph}\n` // Manually add the number and increment it
          number++
        } else {
          output += `${paragraph}\n`
        }
      }
    })
    output += "\n"
  })
  const element = document.createElement("a")
  const file = new Blob([output], { type: "text/plain" })
  element.href = URL.createObjectURL(file)
  element.download = "EULA.txt"
  document.body.appendChild(element)
  element.click()
  setLoading(false)
}
