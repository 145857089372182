import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Navbar = styled.nav`
  background-color: #333;
  color: #fff;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`

export const Main = styled.div`
  margin-top: 100px;
  flex: 1;

  @media (max-width: 1023px) {
    margin-top: 68px;
  }
`

export const Footer = styled.footer`
  background-color: #333;
  color: #fff;
`
