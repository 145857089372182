import React, { useEffect, useState, useRef } from "react"
import {
  CountryHolder,
  CountrySearch,
  CountryContainer,
  IconHolder,
  CountryWrapper,
  CountryTag,
  TagHolder,
  CountryName,
  TagItem,
  DisplayTagedCountries,
  SearchIconStyled,
} from "./country-selector.styled"
import SearchIcon from "../../../../images/search.svg"
import { countryTagList } from "../../../../helper/CountryTags"

const CountrySelector = ({
  setPrivacyForm,
  applicableCountries,
  privacyForm,
}) => {
  const [checkedCountries, setCheckedCountries] = useState({})
  const [searchInput, setSearchInput] = useState("")
  const [isTouched, setIsTouched] = useState(false)
  const wrapperRef = useRef(null)

  const handleCountryCheck = (countryCode, countryName) => {
    setCheckedCountries((prevCheckedCountries) => {
      const updatedCountries = {}
      if (prevCheckedCountries[countryCode]) {
        delete updatedCountries[countryCode]
      } else {
        updatedCountries[countryCode] = countryName
        setSearchInput(countryName)
      }
      return updatedCountries
    })
  }

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value)
  }

  const filteredCountryTags = Object.entries(countryTagList).filter(
    ([countryCode, countryName]) =>
      countryName.toLowerCase().includes(searchInput.toLowerCase())
  )

  const allTagChecked =
    Object.keys(checkedCountries).length === Object.keys(countryTagList).length

  const handleAllTagCheck = () => {
    if (allTagChecked) {
      setCheckedCountries({})
    } else {
      setCheckedCountries({ ...countryTagList })
    }
  }

  useEffect(() => {
    setPrivacyForm({
      ...privacyForm,
      applicableCountries: checkedCountries,
    })
  }, [checkedCountries])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsTouched(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <CountryWrapper ref={wrapperRef}>
      <CountryContainer hide={isTouched === false}>
        <IconHolder hide={isTouched === false} active={isTouched === false}>
          <SearchIconStyled src={SearchIcon} />
        </IconHolder>
        <CountrySearch
          hide={isTouched === false}
          active={isTouched === false}
          type="text"
          placeholder="e.g. Canada, United States"
          value={searchInput}
          onChange={handleSearchInputChange}
          onFocus={() => {
            setIsTouched(true)
          }}
        />
      </CountryContainer>
      <CountryHolder hide={isTouched === false}>
        <TagHolder>
          {/* <TagItem>
            <CountryTag
              type="checkbox"
              checked={allTagChecked}
              onChange={handleAllTagCheck}
            />
            <CountryName weight={700}>All</CountryName>
          </TagItem> */}
          {filteredCountryTags.map(([countryCode, countryName]) => (
            <TagItem key={countryCode}>
              <CountryTag
                type="checkbox"
                checked={!!checkedCountries[countryCode]}
                onChange={() => handleCountryCheck(countryCode, countryName)}
              />
              <CountryName>{countryName}</CountryName>
            </TagItem>
          ))}
        </TagHolder>
      </CountryHolder>
      {/* <DisplayTagedCountries hide={isTouched}>
        {Object.entries(checkedCountries).map(([countryCode, countryName]) => (
          <TagItem key={countryCode}>
            <CountryTag
              type="checkbox"
              checked={!!checkedCountries[countryCode]}
              onChange={() => handleCountryCheck(countryCode, countryName)}
            />
            <CountryName weight={700}>{countryName}</CountryName>
          </TagItem>
        ))}
      </DisplayTagedCountries> */}
    </CountryWrapper>
  )
}

export default CountrySelector
