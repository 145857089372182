import React, { useEffect, useState } from "react"
import {
  CheckBoxItem,
  PopupHolder,
  QuestionIcon,
  QuestionWrapper,
} from "../company-questions/company-questions.styled"
import {
  DateHolder,
  DateTitle,
  InputErrorMessage,
  InputHolder,
  InputTitle,
  QuestionSpan,
} from "../../../shared/shared.styles"
import {
  CheckBox,
  CheckBoxHolder,
  EulaInput,
  EulaTextArea,
} from "../../../shared/input"
import QuestionMarkIcon from "../../../images/question-mark-outline-svgrepo-com.svg"

const SoftwareQuestions = ({
  stepsCompleted,
  softwareForm,
  setSoftwareForm,
  softwareFormValidity,
  setSoftwareFormValidity,
}) => {
  const [popup, setPopup] = useState(null)

  const {
    softwareName,
    softwareLink,
    softwareDescription,
    licenseChecks,
    accountRegistration,
    severalUsers,
    numberOfDevices,
    automaticUpdate,
    trialPeriod,
    subscriptionExpireNotice,
    subscriptionPlan,
    trialDuration,
  } = softwareForm

  const {
    softwareNameValid,
    softwareDescriptionValid,
    softwareLinkValid,
    trialDateValid,
  } = softwareFormValidity

  useEffect(() => {
    setSoftwareFormValidity({
      ...softwareFormValidity,
      softwareNameValid: true,
    })
  }, [softwareName])

  useEffect(() => {
    setSoftwareFormValidity({
      ...softwareFormValidity,
      softwareDescriptionValid: true,
    })
  }, [softwareDescription])

  useEffect(() => {
    setSoftwareFormValidity({
      ...softwareFormValidity,
      softwareLinkValid: true,
    })
  }, [softwareLink])

  useEffect(() => {
    setSoftwareFormValidity({
      ...softwareFormValidity,
      numberOfDevicesValid: true,
    })
  }, [numberOfDevices])

  useEffect(() => {
    setSoftwareFormValidity({
      ...softwareFormValidity,
      subscriptionExpireNoticeValid: true,
    })
  }, [subscriptionExpireNotice])

  useEffect(() => {
    setSoftwareFormValidity({
      ...softwareFormValidity,
      trialDateValid: true,
    })

    console.log("Trial duration", trialDuration)
  }, [trialDuration, trialPeriod])

  return (
    <QuestionWrapper show={stepsCompleted === 1}>
      <InputHolder bottom>
        <InputTitle>What Is the Name of Your Software?</InputTitle>
        <EulaInput
          value={softwareName}
          onChange={(e) => {
            setSoftwareForm({
              ...softwareForm,
              softwareName: e.target.value,
            })
          }}
          placeholder="e.g. Photoshop"
        />
        {!softwareNameValid && (
          <InputErrorMessage>
            Please enter a valid software name.
          </InputErrorMessage>
        )}
      </InputHolder>
      <InputHolder bottom>
        <InputTitle>Provide a Brief Description of Your Software:</InputTitle>
        <EulaTextArea
          placeholder="e.g. Photoshop is a powerful image editing software."
          maxLength="250"
          value={softwareDescription}
          onChange={(e) => {
            setSoftwareForm({
              ...softwareForm,
              softwareDescription: e.target.value,
            })
          }}
        ></EulaTextArea>

        {!softwareDescriptionValid && (
          <InputErrorMessage>
            Please provide a valid description.
          </InputErrorMessage>
        )}
      </InputHolder>
      <InputHolder bottom>
        <InputTitle>
          Provide the link where the software can be accessed:
        </InputTitle>
        <EulaInput
          urlPlaceholder
          value={softwareLink}
          onChange={(e) => {
            setSoftwareForm({
              ...softwareForm,
              softwareLink: e.target.value,
            })
          }}
          placeholder="e.g. www.yourwebsite.com/software-link"
        />
        {!softwareLinkValid && (
          <InputErrorMessage>
            Please enter a valid software link.
          </InputErrorMessage>
        )}
      </InputHolder>
      <InputHolder bottom checkbox>
        <InputTitle>
          Does Your Software Use License Checks?
          <QuestionSpan
            onMouseEnter={() => setPopup(0)}
            onMouseLeave={() => setPopup(null)}
          >
            <QuestionIcon src={QuestionMarkIcon} />
          </QuestionSpan>
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setSoftwareForm({
                  ...softwareForm,
                  licenseChecks: true,
                })
              }}
              type="checkbox"
              checked={licenseChecks}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  licenseChecks: false,
                })
              }}
              type="checkbox"
              checked={!licenseChecks}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
        <PopupHolder
          onMouseEnter={() => setPopup(0)}
          onMouseLeave={() => setPopup(null)}
          popup={popup === 0}
          top={80}
        >
          License checks are a service that verifies license entitlements.
        </PopupHolder>
      </InputHolder>
      <InputHolder bottom checkbox>
        <InputTitle>
          Does Using or Accessing the Software Require an Account Registration?
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setSoftwareForm({
                  ...softwareForm,
                  accountRegistration: true,
                })
              }}
              type="checkbox"
              checked={accountRegistration}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  accountRegistration: false,
                })
              }}
              type="checkbox"
              checked={!accountRegistration}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
      </InputHolder>
      <InputHolder bottom checkbox>
        <InputTitle>
          Does the Software Allow Multiple Users to Access It With a Single
          License?
          <QuestionSpan
            onMouseEnter={() => setPopup(1)}
            onMouseLeave={() => setPopup(null)}
          >
            <QuestionIcon src={QuestionMarkIcon} />
          </QuestionSpan>
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  severalUsers: true,
                })
              }}
              type="checkbox"
              checked={severalUsers}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  severalUsers: false,
                  numberOfDevices: "",
                })
              }}
              type="checkbox"
              checked={severalUsers === false}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
        <PopupHolder
          onMouseEnter={() => setPopup(1)}
          onMouseLeave={() => setPopup(null)}
          popup={popup === 1}
          top={90}
        >
          Concurrent use is a software licensing term that allows a specified
          number of users to access software simultaneously. For example, if you
          purchase a license enabling 10 concurrent users, then any 10 people
          within your organization or business can use the program at once.
        </PopupHolder>
      </InputHolder>
      {severalUsers && (
        <InputHolder bottom>
          <InputTitle>
            With one license, how many concurrent devices can access the
            software?
          </InputTitle>
          <EulaInput
            onChange={(e) => {
              const inputValue = parseInt(e.target.value)
              const newNumberOfDevices = inputValue >= 1 ? inputValue : 1
              setSoftwareForm({
                ...softwareForm,
                numberOfDevices: newNumberOfDevices,
              })
            }}
            type="number"
            min={1}
            value={numberOfDevices}
            placeholder="e.g. 2"
          />
          {!softwareFormValidity.numberOfDevicesValid &&
            numberOfDevices === "" && (
              <InputErrorMessage>
                Please provide a valid input.
              </InputErrorMessage>
            )}
        </InputHolder>
      )}
      <InputHolder bottom checkbox>
        <InputTitle>
          Is automatic update technology included in the software?
          <QuestionSpan
            onMouseEnter={() => setPopup(2)}
            onMouseLeave={() => setPopup(null)}
          >
            <QuestionIcon src={QuestionMarkIcon} />
          </QuestionSpan>
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setSoftwareForm({
                  ...softwareForm,
                  automaticUpdate: true,
                })
              }}
              type="checkbox"
              checked={automaticUpdate}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  automaticUpdate: false,
                })
              }}
              type="checkbox"
              checked={!automaticUpdate}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
        <PopupHolder
          onMouseEnter={() => setPopup(2)}
          onMouseLeave={() => setPopup(null)}
          popup={popup === 2}
          top={80}
        >
          Automatic software updates are the process in which your software
          applies critical updates without seeking confirmation from a user.
        </PopupHolder>
      </InputHolder>
      <InputHolder checkbox>
        <InputTitle>
          Is the Software Provided as a Trial?
          <QuestionSpan
            onMouseEnter={() => setPopup(3)}
            onMouseLeave={() => setPopup(null)}
          >
            <QuestionIcon src={QuestionMarkIcon} />
          </QuestionSpan>
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  trialPeriod: true,
                })
              }}
              type="checkbox"
              checked={trialPeriod}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  trialPeriod: false,
                  trialDuration: "",
                })
              }}
              type="checkbox"
              checked={!trialPeriod}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
        <PopupHolder
          onMouseEnter={() => setPopup(3)}
          onMouseLeave={() => setPopup(null)}
          popup={popup === 3}
          top={80}
        >
          Software that can be run for a limited period of time before it
          expires.
        </PopupHolder>
      </InputHolder>
      {trialPeriod && (
        <DateHolder>
          <DateTitle>How many days is the trial?</DateTitle>
          <EulaInput
            onChange={(e) => {
              const days = parseInt(e.target.value)
              setSoftwareForm({
                ...softwareForm,
                trialDuration: days,
              })
            }}
            type="number"
            min={1}
            value={trialDuration}
            placeholder="e.g. 7"
          />
        </DateHolder>
      )}
      {!trialDateValid && (
        <InputErrorMessage>
          Please provide a valid date range.
        </InputErrorMessage>
      )}
      <InputHolder bottom top checkbox>
        <InputTitle>
          Is the Software Accessed With a Subscription Plan?
          <QuestionSpan
            onMouseEnter={() => setPopup(4)}
            onMouseLeave={() => setPopup(null)}
          >
            <QuestionIcon src={QuestionMarkIcon} />
          </QuestionSpan>
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setSoftwareForm({
                  ...softwareForm,
                  subscriptionPlan: true,
                })
              }}
              type="checkbox"
              checked={subscriptionPlan}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={() => {
                setSoftwareForm({
                  ...softwareForm,
                  subscriptionPlan: false,
                  subscriptionExpireNotice: "",
                })
              }}
              type="checkbox"
              checked={!subscriptionPlan}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
        <PopupHolder
          onMouseEnter={() => setPopup(4)}
          onMouseLeave={() => setPopup(null)}
          popup={popup === 4}
          top={80}
        >
          With a subscription license, instead of making a one-time purchase,
          you pay a monthly or annual fee to use the software.
        </PopupHolder>
      </InputHolder>
      {subscriptionPlan && (
        <InputHolder>
          <InputTitle>
            How many days is the notice period required to withdraw from a
            Subscription Plan?
          </InputTitle>
          <EulaInput
            onChange={(e) => {
              const noticeValue = parseInt(e.target.value)
              const newNoticeValue = noticeValue >= 1 ? noticeValue : 1
              setSoftwareForm({
                ...softwareForm,
                subscriptionExpireNotice: newNoticeValue,
              })
            }}
            type="number"
            min={1}
            value={subscriptionExpireNotice}
            placeholder="e.g. 2"
          />
          {!softwareFormValidity.subscriptionExpireNoticeValid &&
            subscriptionExpireNotice === "" && (
              <InputErrorMessage>
                Please provide a valid input.
              </InputErrorMessage>
            )}
        </InputHolder>
      )}
    </QuestionWrapper>
  )
}

export default SoftwareQuestions
