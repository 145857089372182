import React, { useEffect, useState } from "react"
import {
  CheckBoxItem,
  PopupHolder,
  QuestionIcon,
  QuestionWrapper,
} from "../company-questions/company-questions.styled"
import {
  InputErrorMessage,
  InputHolder,
  InputTitle,
  QuestionSpan,
} from "../../../shared/shared.styles"
import { CheckBox, CheckBoxHolder, EulaInput } from "../../../shared/input"
import CountrySelector from "./country-selector/country-selector"
import QuestionMarkIcon from "../../../images/question-mark-outline-svgrepo-com.svg"

const PrivacyQuestions = ({
  stepsCompleted,
  privacyForm,
  setPrivacyForm,
  privacyFormValidity,
  setPrivacyFormValidity,
}) => {
  const [popup, setPopup] = useState(null)
  let timer
  const clearPopupWithDelay = () => {
    timer = setTimeout(() => {
      setPopup(null)
    }, 500)
  }
  const clearPopupDelay = () => {
    clearTimeout(timer)
  }

  const {
    effectiveDate,
    applicableCountries,
    relevantCourt,
    monetaryCapSelected,
    monetaryCap,
    dataProcessingLink,
    dataProcessing,
  } = privacyForm
  const {
    effectiveDateValid,
    relevantCourtValid,
    monetaryCapValid,
    dataProcessingLinkValid,
    countrieSelectedValid,
  } = privacyFormValidity

  useEffect(() => {
    setPrivacyFormValidity({
      ...privacyFormValidity,
      effectiveDateValid: true,
    })
  }, [effectiveDate])

  useEffect(() => {
    setPrivacyFormValidity({
      ...privacyFormValidity,
      relevantCourtValid: true,
    })
  }, [relevantCourt])

  useEffect(() => {
    setPrivacyFormValidity({
      ...privacyFormValidity,
      dataProcessingLinkValid: true,
    })
  }, [dataProcessingLink])

  useEffect(() => {
    setPrivacyFormValidity({
      ...privacyFormValidity,
      countrieSelectedValid: true,
    })
  }, [applicableCountries])

  return (
    <QuestionWrapper show={stepsCompleted === 1}>
      <InputHolder bottom>
        <InputTitle>What Is the Effective Date of the EULA?</InputTitle>
        <EulaInput
          onChange={(e) => {
            setPrivacyForm({
              ...privacyForm,
              effectiveDate: e.target.value,
            })
          }}
          type="date"
          value={effectiveDate}
          placeholder="Select a date"
        />
        {!effectiveDateValid && effectiveDate === "" && (
          <InputErrorMessage>Please provide a valid date.</InputErrorMessage>
        )}
      </InputHolder>
      <InputHolder bottom>
        <InputTitle>What country’s law is applicable to the EULA?</InputTitle>
        <CountrySelector
          applicableCountries={applicableCountries}
          setPrivacyForm={setPrivacyForm}
          privacyForm={privacyForm}
        />
        {!countrieSelectedValid && (
          <InputErrorMessage>
            Please enter a valid list of countries
          </InputErrorMessage>
        )}
      </InputHolder>
      <InputHolder bottom>
        <InputTitle>
          In Case of Litigation, What Is the Place of the Relevant Court
          Specified in the Agreement?
        </InputTitle>
        <EulaInput
          value={relevantCourt}
          onChange={(e) => {
            setPrivacyForm({
              ...privacyForm,
              relevantCourt: e.target.value,
            })
          }}
          placeholder="e.g. New York, USA"
        />
        {!relevantCourtValid && (
          <InputErrorMessage>Please enter a valid Court.</InputErrorMessage>
        )}
      </InputHolder>
      <InputHolder bottom checkbox>
        <InputTitle>
          Include Monetary Cap?{" "}
          <QuestionSpan
            onMouseEnter={() => setPopup(0)}
            onMouseLeave={clearPopupWithDelay}
          >
            <QuestionIcon src={QuestionMarkIcon} />
          </QuestionSpan>
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setPrivacyForm({
                  ...privacyForm,
                  monetaryCapSelected: true,
                })
              }}
              type="checkbox"
              checked={monetaryCapSelected}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setPrivacyForm({
                  ...privacyForm,
                  monetaryCapSelected: false,
                  monetaryCap: "",
                })
              }}
              type="checkbox"
              checked={!monetaryCapSelected}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>

        <PopupHolder
          onMouseEnter={() => {
            clearPopupDelay()
            setPopup(0)
          }}
          onMouseLeave={() => setPopup(null)}
          popup={popup === 0}
          top={80}
        >
          A liability cap is a contractual clause or agreement that limits the
          amount of damages a client can claim from a professional services
          firm, in the event of negligence or a breach of contract.
        </PopupHolder>
      </InputHolder>
      {monetaryCapSelected && (
        <InputHolder bottom>
          <InputTitle>What Is the Monetary Cap to Your Liability?</InputTitle>
          <EulaInput
            value={monetaryCap}
            onChange={(e) => {
              setPrivacyForm({
                ...privacyForm,
                monetaryCap: e.target.value,
              })
            }}
            placeholder="e.g. $100,000 USD"
            // onChange={(e) => {
            //   const monetaryValue = parseInt(e.target.value)
            //   const newValue = monetaryValue >= 1 ? monetaryValue : null // Set to null if below zero or NaN
            //   setPrivacyForm({
            //     ...privacyForm,
            //     monetaryCap: newValue,
            //   })
            // }}
            // onKeyPress={(e) => {
            //   if (e.key === "-" || e.key === "+") {
            //     e.preventDefault()
            //   }
            // }}
            // type="number"
            // min={1}
          />
          {!monetaryCapValid && monetaryCap === "" && (
            <InputErrorMessage>
              Please provide a valid monetary cap.
            </InputErrorMessage>
          )}
        </InputHolder>
      )}
      <InputHolder bottom checkbox>
        <InputTitle>
          Do You Qualify as a “Processor” of Personal Data Under the GDPR
          (General Data Protection Regulation) ?
          <QuestionSpan
            onMouseEnter={() => setPopup(1)}
            onMouseLeave={clearPopupWithDelay}
          >
            <QuestionIcon src={QuestionMarkIcon} />
          </QuestionSpan>
        </InputTitle>
        <CheckBoxHolder>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setPrivacyForm({
                  ...privacyForm,
                  dataProcessing: true,
                })
              }}
              type="checkbox"
              checked={dataProcessing}
              readOnly
            />
            Yes
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              onClick={(e) => {
                setPrivacyForm({
                  ...privacyForm,
                  dataProcessing: false,
                })
              }}
              type="checkbox"
              checked={!dataProcessing}
              readOnly
            />
            No
          </CheckBoxItem>
        </CheckBoxHolder>
        <PopupHolder
          onMouseEnter={() => {
            clearPopupDelay()
            setPopup(1)
          }}
          onMouseLeave={() => setPopup(null)}
          popup={popup === 1}
          top={80}
        >
          The UK GDPR defines a processor as a natural or legal person, public
          authority, agency or other body which processes personal data on
          behalf of the controller. For more information about processors and
          controllers, see
          https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/controllers-and-processors/controllers-and-processors/what-are-controllers-and-processors/#:~:text=a%20processor%20as%3A-,'processor'%20means%20a%20natural%20or%20legal%20person%2C%20public%20authority,controller%20and%20under%20their%20authority.
        </PopupHolder>
      </InputHolder>
      {dataProcessing && (
        <InputHolder>
          <InputTitle>
            Provide the Link to Your Data Processing Agreement
            <QuestionSpan
              onMouseEnter={() => setPopup(2)}
              onMouseLeave={clearPopupWithDelay}
            >
              <QuestionIcon src={QuestionMarkIcon} />
            </QuestionSpan>
          </InputTitle>
          <EulaInput
            value={dataProcessingLink}
            onChange={(e) => {
              setPrivacyForm({
                ...privacyForm,
                dataProcessingLink: e.target.value,
              })
            }}
            type="url"
            placeholder="e.g www.yourwebsite.com/data-processing-agreement"
          />
          {!dataProcessingLinkValid && (
            <InputErrorMessage>
              Provide a valid data processing agreement link.
            </InputErrorMessage>
          )}
          <PopupHolder
            onMouseEnter={() => {
              clearPopupDelay()
              setPopup(2)
            }}
            onMouseLeave={() => setPopup(null)}
            popup={popup === 2}
            top={40}
          >
            A data processing agreement is an agreement between a data
            controller and a data processor that regulates any personal data
            processing conducted for business purposes. For more information
            about DPAs:
            https://ironcladapp.com/journal/contracts/what-is-a-data-processing-agreement-dpa/
          </PopupHolder>
        </InputHolder>
      )}
    </QuestionWrapper>
  )
}

export default PrivacyQuestions
