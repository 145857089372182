import React from "react"
import {
  InnerContainer,
  RightDiv,
  TemplateEulaWrapper,
} from "./eula-template.styled"
import { Container, SubTitle } from "../../shared/shared.styles"
import Image from "../../images/CTA.png"
import { Button } from "../../shared/button"

const EulaTemplate = ({ setGenerateOn }) => {
  return (
    <TemplateEulaWrapper>
      <Container>
        <InnerContainer>
          <div>
            <img
              src={Image}
              alt="Handshake symbolizing the completion of a EULA."
            />
          </div>
          <RightDiv>
            <SubTitle white fs={48} center>
              Generate a EULA Today!
            </SubTitle>
            <Button
              color="#F7F7F7"
              shadow
              type="light"
              mobile
              onClick={() => {
                setGenerateOn(true)
              }}
            >
              Generate now
            </Button>
          </RightDiv>
        </InnerContainer>
      </Container>
    </TemplateEulaWrapper>
  )
}

export default EulaTemplate
