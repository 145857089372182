import React from "react"
import { OrderedList } from "./tab.styles"

const Miscellaneous = ({ EulaData }) => {
  const { softwareForm, privacyForm } = EulaData
  const { softwareName } = softwareForm
  const { applicableCountries } = privacyForm

  return (
    <div>
      <OrderedList>
        <li>
          {" "}
          Assignment. You are not allowed to assign this EULA or any rights
          hereunder, unless with Our previous written consent. Conversely, We
          are allowed, at Our sole discretion, to assign this EULA or any rights
          hereunder to any third party, without giving You any prior notice.{" "}
        </li>
        <li>
          {" "}
          Force majeure. A Party shall not be responsible for any failure to
          perform due to any event of Force Majeure. In the event of any such
          delay, the affected Party will be excused from such performance to the
          extent it is delayed or prevented by such cause. However, the other
          Party may terminate this EULA forthwith on written notice if such
          Force Majeure condition continues for a period of sixty (60) days.
        </li>
        <li>
          {" "}
          No implied waiver. Any tolerance or silence by Us, even if on a
          continuous and reiterated basis, in relation to any breach or default
          by You of any provision of this EULA shall not be considered as a
          consent to such breaches and defaults and shall not affect the
          validity of the clause breached nor shall be construed as a waiver to
          any of the rights pertaining to Us pursuant to this EULA and/or the
          applicable Law.
        </li>
        <li>
          {" "}
          Governing law and exclusive jurisdiction. This EULA is governed by the
          substantive and procedural Laws of{" "}
          {Object.values(applicableCountries).length > 0 ? (
            Object.values(applicableCountries).join(", ")
          ) : (
            <>[Jurisdiction_Place]</>
          )}
          . Any dispute arising out of, or relating to, this EULA shall be
          submitted to the exclusive jurisdiction of the Court of{" "}
          {Object.values(applicableCountries).length > 0 ? (
            Object.values(applicableCountries).join(", ")
          ) : (
            <>[Jurisdiction_Place]</>
          )}
          .
        </li>
        <li>
          {" "}
          Severability. Should any part of this EULA be declared illegal or
          unenforceable, the remaining portion will remain in full force and
          effects.
        </li>
        <li>
          {" "}
          Amendments. The effective date of the last version of this EULA is
          written above. We have the right to amend this EULA at any time, and
          to change, delete, discontinue or impose conditions on use of the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software, in which
          case the new terms and conditions will supersede prior terms. Any
          changes will be effective after thirty (30) days from the time We
          first notify You about such changes via email or/and notifications
          while using the {!softwareName ? "[Software_Name]" : softwareName}{" "}
          Software and/or other reasonable means. Your continued use of the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software following
          changes to this EULA after the effective date of a revised version
          thereof constitutes Your expressed acceptance of, and agreement to be
          bound by, this EULA as in force from time to time. If You do not
          accept modified EULA in part or full, You must terminate Your account
          and stop using the {!softwareName ? "[Software_Name]" : softwareName}{" "}
          Software before the change takes effect.
        </li>
      </OrderedList>
    </div>
  )
}

export default Miscellaneous
