import styled from "styled-components"

export const CountryWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "#fff")};
  padding: 8px 16px 0px 0px;
  padding-right: 0;
`
export const CountryContainer = styled.div`
  display: flex;
  padding: 8px 0px 0px 0px;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  border-radius: 0px;
  border-bottom: ${(props) => (props.hide ? "none" : "1px solid #cfcfcf")};
`
export const IconHolder = styled.div`
  background-color: #fff;
  padding: 8px 16px;
  border-radius: ${(props) => (props.hide ? "8px" : "0px")};
  margin-right: -23px;

  padding-top: ${(props) => (props.active ? "4px" : "3px")};
  border: ${(props) => (props.active ? "none" : "1px solid #2BB0ED")};
  border-bottom: none;
  border-top-left-radius: 0px;
`

export const SearchIconStyled = styled.img`
  min-height: 26.33px;
  min-width: 26.33px;
`

export const CountrySearch = styled.input`
  min-height: 42px;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid #fff;
  background: #fff;
  color: #7e7e7e;
  font-family: Karla;
  font-size: 16px;
  font-weight: ${(weight) => (weight ? weight : "700")};
  line-height: 26px;
  color: #0b69a3;
  border: ${(props) => (props.active ? "none" : "1px solid #2BB0ED")};
  border-bottom: none;
  border-left: none;
  border-bottom-right-radius: ${(props) => (props.active ? "none" : "0px")};
  border-top-left-radius: 0px;

  &:focus {
    font-weight: 700;
    border-radius: 0px;

    background: #fff;
  }

  &:focus::placeholder {
    font-weight: 400;
  }
`

export const CountryHolder = styled.div`
  display: ${(props) => (props.hide ? "none" : "block")};
  height: 110px;
  padding-top: 8px;
  padding-left: 16px;
  background-color: #fff;
  overflow-y: scroll;

  border: ${(props) => (props.hide ? "none" : "1px solid #2BB0ED")};
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }
`

export const TagHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`

export const CountryTag = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  border-radius: 6px;
  background: var(--colors-neutral-white, #fff);
  box-shadow: 0px 4px 8px 0px rgba(50, 50, 71, 0.06),
    0px 4px 4px 0px rgba(50, 50, 71, 0.08);

  &:checked {
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 8.5px;
    width: 5px;
    height: 10px;
    border: solid #7e7e7e;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

export const CountryName = styled.div`
  color: #7e7e7e;
  font-family: Karla;
  font-size: 12px;
  font-style: normal;
  font-weight: ${(weight) => (weight ? `${weight}` : "400")};
  line-height: 26px;
`

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 0;
`

export const DisplayTagedCountries = styled.div`
  display: ${(props) => (props.hide ? "none" : "felx")};
  padding-top: 17px;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  max-height: 100px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: grey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0b69a3;
    border-radius: 4px;
  }
`
