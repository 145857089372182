import React from "react"
import { PreviewTabContainer, PreviewTabWrapper } from "./preview-tab.styled"
import { PreviewTitles } from "../../../shared/shared.styles"
import {
  PreviewContetText,
  PreviewContetTitle,
  TabContent,
} from "../preview-section.styled"
import Plus from "../../../images/Plus.svg"
import Arrow from "../../../images/arrow.svg"

const PreviewTab = (props) => {
  return (
    <PreviewTabWrapper>
      <PreviewTabContainer onClick={props.onClick}>
        <PreviewTitles>{props.title}</PreviewTitles>
        {props.openTab ? <img src={Arrow} /> : <img src={Plus} />}
      </PreviewTabContainer>
      {props.openTab && <TabContent>{props.component}</TabContent>}
    </PreviewTabWrapper>
  )
}

export default PreviewTab
