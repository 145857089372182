import styled from "styled-components"
import Grid from "../../images/Grid.png"

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 85vh;
  padding: 16px;
  gap: 30px;
  width: 40%;
  border-radius: 8px;
  border: 2px solid #f7f7f7;
  background: #f7f7f7;

  @media (max-width: 765px) {
    width: 50%;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
`

export const ImageStepZero = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 0px 0px 0px 32px;
  background-image: url(${Grid});
  background-color: #13b0fc;
  background-size: 60%;
  background-position: center;

  box-shadow: 0px 8px 8px 0px rgba(43, 176, 237, 0.25);
`
