import React from "react"
import {
  ButtonHolder,
  EulaUseFlexBox,
  EulaUseFlexItem,
  UseEulaWrapper,
} from "./use-eula.styled"
import {
  Container,
  MainTitle,
  Paragraph,
  SubTitle,
} from "../../shared/shared.styles"
import Q1 from "../../images/Q1.png"
import Q2 from "../../images/Q2.png"
import Q3 from "../../images/q3.png"
import { Button } from "../../shared/button"

const EulaUse = ({ setGenerateOn }) => {
  return (
    <UseEulaWrapper>
      <Container>
        <MainTitle>
          How do I use LicenseSpring’s
          <br />
          Free EULA Generator?
        </MainTitle>
        <EulaUseFlexBox>
          <EulaUseFlexItem>
            <img
              src={Q1}
              alt="Bubbles indicating a question being asked and answer being given."
            />
            <SubTitle fs={24}>Step 1</SubTitle>
            <Paragraph center maxw={336}>
              Answer a series of questions about your company, software, and
              specific EULA requirements. This helps tailor the EULA to your
              needs.
            </Paragraph>
          </EulaUseFlexItem>
          <EulaUseFlexItem>
            <img src={Q2} alt="Page indicating a document being uploaded." />
            <SubTitle fs={24}>Step 2</SubTitle>
            <Paragraph center maxw={336}>
              Once generated, publish the EULA and integrate it with your
              software, ensuring it is accessible to users.
            </Paragraph>
          </EulaUseFlexItem>
          <EulaUseFlexItem>
            <img src={Q3} alt="Checkmark signifying completion." />
            <SubTitle fs={24}>Step 3</SubTitle>
            <Paragraph center maxw={336}>
              Your custom EULA is now ready and in place—you're all set!
            </Paragraph>
          </EulaUseFlexItem>
        </EulaUseFlexBox>
        <ButtonHolder>
          <Button
            color="#F7F7F7"
            shadow
            type="light"
            width={100}
            mobile
            onClick={() => {
              setGenerateOn(true)
            }}
          >
            Generate now
          </Button>
        </ButtonHolder>
      </Container>
    </UseEulaWrapper>
  )
}

export default EulaUse
