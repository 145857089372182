import React from "react"
import { OrderedList } from "./tab.styles"

const Responsibilities = ({ EulaData }) => {
  const { softwareForm, privacyForm } = EulaData

  const { softwareName } = softwareForm

  const { dataProcessing, dataProcessingLink } = privacyForm

  return (
    <div>
      <OrderedList>
        <li>
          General responsibilities. You are entirely responsible for the use of
          the {!softwareName ? "[Software_Name]" : softwareName} Software and,
          to such purpose, You shall not use, encourage, promote, facilitate,
          instruct (or induce others to do the foregoing on) the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software for any
          activity that violates any applicable Law or to transmit, store,
          display, distribute or otherwise make available any Data that is
          illegal or that would encourage or constitute a criminal or civil
          liability under any Law.
        </li>
        <li>
          Data lawfulness. You undertake to have all the interest and title
          (including, without limitation, any related Intellectual Property
          Right) in and to the Data that You may transmit, store, display,
          distribute or otherwise make available through the{" "}
          {!softwareName ? "[Software_Name]" : softwareName}
          Software, and that You are the sole responsible for the accuracy,
          quality, integrity, legality, reliability, and appropriateness
          thereof. Without limiting the foregoing, You warrant and represent
          that such Data is fully compliant with any applicable Law. In no way
          whatsoever We will be responsible for any of such Data.
        </li>
        {dataProcessing && (
          <li>
            Personal Data. Without limiting the foregoing, in case any Data is a
            Personal Data, You appoint Us as “processor” of the said Personal
            Data in order to process them for the purpose of hosting, processing
            (including, without limitation, reformat, manipulate, etc.) at the
            purpose to provide You with the Services. In particular, by
            accepting this EULA, You: (i) warrant and represent that the said
            Personal Data are compliant with the applicable Personal Data
            Protection Laws (including, without limitation, Your obligation to
            use such Personal Data only to the limited extent that is necessary
            and relevant to the Services and for no other purpose unless You
            have obtained the prior express consent from the related data
            subject, etc.); (ii) accept to be bound also by Our Data Processing
            Agreement available at $
            {dataProcessingLink ? dataProcessingLink : "[DPA_link]"}, which
            shall be an integral part of this EULA and is incorporated herein by
            reference.
          </li>
        )}
        <li>
          Updates. In case the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software needs any
          update as provided in Sect. 5.1) below, You expressly agree to install
          such updates as soon as they are available, bearing any and all the
          related costs, if any. You shall bear full risk of any loss or damage
          in case any updates are not properly and timely installed due to Your
          fault; We expressly disclaim any liability in such respect.
        </li>
        <li>
          Export regulations compliance. You expressly acknowledge that You are
          not an entity targeted by any Law which provides economic sanctions,
          export controls, import regulations or trade embargoes, nor You are
          otherwise owned or controlled by, or acting on behalf of, any person
          or entity targeted or in any way affected by the foregoing.
          Furthermore, You expressly acknowledge that You will not download or
          otherwise export or re-export the{" "}
          {!softwareName ? "[Software_Name]" : softwareName} Software’s content,
          products or services or any related technical data directly or
          indirectly to any person or entity targeted by the aforementioned Law
          or for any end-use prohibited or restricted by such Law.
        </li>
        <li>
          Indemnification. To any third party’s claim arising out of a violation
          of Your obligations under this Section 4 (Your Responsibilities),
          Sects. 10.1) and 10.2) below shall apply, in addition to any other
          rights or remedies afforded to Us under this EULA and/or as per the
          applicable Law.
        </li>
      </OrderedList>
    </div>
  )
}

export default Responsibilities
