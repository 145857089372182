import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: ${(props) => (props.background ? props.color : "#FFF")};
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 90%;
  display: flex;
  flex-direction: column;

  @media (max-width: 400px) {
    width: ${(props) => props.noBorder && "100%"};
  }
`

//Landing Page - stlyles

export const MainTitle = styled.h2`
  margin: ${(props) => props.margin};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};
  color: ${(props) => (props.white ? "#FFF" : "#035388")};
  text-align: center;
  font-size: 48px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.5px;
  text-transform: capitalize;

  @media (max-width: 1024px) {
    font-size: ${({ mobileFs }) => mobileFs && "40px"};
  }
  @media (max-width: 800px) {
    font-size: ${({ mobileFs }) => mobileFs && "36px"};
  }
`
export const SubTitle = styled.h2`
  margin: ${(props) => props.margin};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};
  color: ${(props) => (props.white ? "#FFF" : "#035388")};
  font-size: ${({ fs }) => (fs ? `${fs}px` : "32px")};
  font-family: Roboto;
  font-weight: 500;
  line-height: 40px;
  text-transform: capitalize;
  text-align: ${(props) => props.center && "center"};
  letter-spacing: ${({ ls }) => ls && `${ls}px`};
  text-align: ${(props) => props.center && "center"};

  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 800px) {
    font-size: 36px;
  }
`
export const Paragraph = styled.p`
  margin: ${(props) => props.margin};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};
  color: ${(props) => (props.white ? "#FFF" : "#035388")};
  font-size: 24px;
  font-family: Karla;
  font-weight: 400;
  line-height: 32px;
  text-align: ${(props) => props.center && "center"};
`

// Eula generator

export const GeneratorContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 400px) {
    width: ${(props) => props.noBorder && "100%"};
  }
`
export const InnerGeneratorContainer = styled.div`
  margin: 16px;
`
export const PreviewTitles = styled.h2`
  text-align: ${(props) => props.center && "center"};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};
  color: #0b69a3;
  font-size: 18px;
  font-family: Karla;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
`
export const EulaText = styled.p`
  text-align: ${(props) => props.center && "center"};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};
  color: #0b69a3;
  font-size: 16px;
  font-family: Karla;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const InputTitle = styled.p`
  text-align: ${(props) => props.center && "center"};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};
  color: #0b69a3;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(weight) => (weight ? `${weight}` : "400")};
  line-height: 26px;
`
export const QuestionSpan = styled.span`
  font-weight: 300;
`
export const InputErrorMessage = styled.p`
  margin: 0;
  padding: 0;
  padding-left: 16px;
  color: #f72075;
  font-family: Karla;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
`

export const InputHolder = styled.div`
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  padding: ${(props) => props.padding};
  border-top: ${(props) => (props.top ? "solid 1px #CFCFCF" : null)};
  border-bottom: ${(props) => (props.bottom ? "solid 1px #CFCFCF" : null)};

  display: ${(props) => (props.checkbox ? "flex" : "block")};
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`
export const DateHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`
export const DateTitle = styled.div`
  text-align: ${(props) => props.center && "center"};
  max-width: ${({ maxw }) => maxw && `${maxw}px`};
  color: #0b69a3;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(weight) => (weight ? `${weight}` : "400")};
  line-height: 24px;
  border-bottom: ${(props) => (props.bottom ? "solid 1px #CFCFCF" : null)};
`
