import React from "react"
import { OrderedList } from "./tab.styles"

const PrivacyDataProtection = ({ EulaData }) => {
  const { CompanyForm, softwareForm } = EulaData
  const { policyLink } = CompanyForm
  const { softwareName } = softwareForm

  return (
    <div>
      <OrderedList>
        <li>
          Privacy Policy. You declare to have carefully read Our Privacy Policy
          (available at {!policyLink ? "[PrivacyPolicy_Link]" : policyLink}) and
          got a clear understanding of how We collect, use, protect or otherwise
          handle Personal Data in the provision of the Services.
        </li>
        <li>
          Anonymized Data analysis. We are allowed to analyze anonymized Data
          (i.e., Data that are no more Personal Data and that, therefore, do not
          include personal identifiers of a person, such as his or her name,
          e-mail address, address, etc.) for the purpose to maintain, update or
          improve the {!softwareName ? "[Software_Name]" : softwareName}{" "}
          Software and/or the Services, as well as to conduct internal
          statistics and analytics on the use and proper functioning of the same
          Services. You have no right, title or interest in any result of these
          analyses performed by Us, which shall be deemed as Our Intellectual
          Property Rights.
        </li>
      </OrderedList>
    </div>
  )
}

export default PrivacyDataProtection
